import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import Status from 'modules/common/components/Status';
import { IRoute } from 'modules/routes/models/types';
import { useStore } from 'services/store';

interface IProps {
  route: IRoute;
}

const RouteStatus = ({ route }: IProps) => {
  const { routes } = useStore();

  useEffect(() => {
    routes.apiStore.getRoutesStatuses();
  }, []);

  const status = routes.routesStatuses.find((s) => s.value === route.status);

  return <Status status={status} isLarge />;
};

export default observer(RouteStatus);
