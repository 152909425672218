import React, { useState } from 'react';

import { ArrowRightOutlined, CarOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

import { translate } from 'modules/localization';
import { IRoute } from 'modules/routes/models/types';
import { useStore } from 'services/store';

interface IProps {
  route: IRoute;
  routeUpdate: () => void;
}

const RouteChangeStatus = ({ route, routeUpdate }: IProps) => {
  const { routes, core } = useStore();

  const isCanBeLoaded = route.status === 'planned' && core.permissions['routes.update'];
  const isCanBeCanceled =
    (route.status === 'planned' || route.status === 'at_loading') &&
    core.permissions['routes.update'];
  const isCanBeStatusChanged = isCanBeLoaded || isCanBeCanceled;

  const [isLoadingSendForLoading, setIsLoadingSendForLoading] = useState(false);
  const setRouteStatus = (item: IRoute, status: string): void => {
    setIsLoadingSendForLoading(true);
    routes.apiStore
      .setRouteStatus(item.guid, status)
      .then(() => {
        setIsLoadingSendForLoading(false);
        routeUpdate();
      })
      .catch(() => setIsLoadingSendForLoading(false));
  };

  return (
    isCanBeStatusChanged && (
      <div className="route__actions-wrap">
        <div className="route__actions">
          {isCanBeLoaded && (
            <Button
              type="link"
              icon={<ArrowRightOutlined />}
              loading={isLoadingSendForLoading}
              onClick={(): void => setRouteStatus(route, 'at_loading')}
            >
              <CarOutlined style={{ marginLeft: '5px' }} />
              {translate('sendForLoading')}
            </Button>
          )}
          {isCanBeCanceled && (
            <Popconfirm
              onConfirm={(): void => setRouteStatus(route, 'canceled')}
              placement="rightTop"
              title={translate('areYouSure')}
            >
              <Button className="route__cancel" type="link" icon={<CloseOutlined />}>
                {translate('cancelTheRoute')}
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
    )
  );
};

export default RouteChangeStatus;
