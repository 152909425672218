import React from 'react';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';

interface IProps {
  order: IOrder;
}

const OrderClient = ({ order }: IProps) => {
  return (
    <InfoFact header={translate('client')}>
      <div className="order__infofact-item">
        <p className="order__infofact-title">{translate('contractor')}</p>
        <p>
          {order?.meta?.recipient_company_short_name ? (
            order?.meta?.recipient_company_short_name
          ) : (
            <>&mdash;</>
          )}
        </p>
      </div>
    </InfoFact>
  );
};

export default OrderClient;
