export const mn_translations: Record<string, string> = {
  planning: 'Төлөвлөлт',
  tariffing: 'Тарификац',
  directory: 'Лавлах',
  logout: 'Гарах',
  order: 'Захиалга',
  orders: 'Захиалгууд',
  ordersList: 'Захиалгын жагсаалт',
  orderStatus: 'Захиалгын статус',
  orderDeliveryDateTime: 'Тооцоолсон цаг',
  orderSizes: 'Ачааны мэдээлэл',
  orderType: 'Төрөл',
  orderDetails: 'Захиалгын мэдээлэл',
  taskTitle: 'Даалгавар',
  tasks: 'Даалгаврууд',
  tasksList: 'Даалгаврын жагсаалт',
  taskType: 'Даалгаврын төрөл',
  taskStatus: 'Даалгаврын статус',
  taskDeliveryDateTime: 'Тооцоолсон цаг',
  taskPlannedDeliveryDateTime: 'Төлөвлөсөн цаг',
  taskRealDeliveryDateTime: 'Бодит цаг',
  taskSizes: 'Ачааны мэдээлэл',
  removeTaskFromRoute: 'Маршутаас хураах',
  cancelTheTask: 'Даалгаврыг цуцлах',
  taskCancelation: 'Даалгаврыг цуцлах',
  finishTheTask: 'Дуусгах',
  route: 'Маршрут',
  routes: 'Маршрутууд',
  routesList: 'Маршрутын жагсаалт',
  routeStatus: 'Маршрутын статус',
  routeStartDate: 'Эхлэх огноо',
  routeGenerator: 'Маршрут үүсгэгч',
  routePlannedDateTime: 'Төлөвлөсөн цаг',
  routeRealDateTime: 'Бодит цаг',
  routeCreated: 'Маршрут үүсгэсэн',
  routeFormTitle: 'Маршрут бүрдүүлж байна',
  routeCreateTitle: 'Маршрут үүсгэж байна',
  routeCalculated: 'Маршрутыг тооцоолсон',
  routeDelete: 'Маршрут устгах',
  addTasksToRoute: 'Маршрутад нэмэх',
  courier: 'Түгээгч',
  couriers: 'Түгээгч нар',
  couriersList: 'Түгээгчийн жагсаалт',
  courierEditSuccess: 'Түгээгчийг амжилттай солисон',
  courierCreateSuccess: 'Түгээгчийг амжилттай үүсгэсэн',
  courierEditTitle: 'Түгээгчийг засварлах',
  courierCreateTitle: 'Түгээгчийг нэмэх',
  deliveryMethod: 'Хүргэлтийн арга',
  deliveryMethods: 'Хүргэлтийн аргууд',
  allDeliveryMethod: 'Бүх хүргэлтийн аргууд',
  withoutDeliveryMethod: 'Хүргэлтгүй',
  deliveryMethodEditSuccess: 'Хүргэлтийн арга амжилттай солисон',
  deliveryMethodCreateSuccess: 'Хүргэлтийн арга амжилттай үүсгэсэн',
  deliveryMethodEditTitle: 'Хүргэлтийн арга засварлах',
  deliveryMethodCreateTitle: 'Хүргэлтийн арга нэмэх',
  deliveryMethodsParams: 'Хүргэлтийн аргын мэдээлэл',
  deliveryMethodTitle: 'Хүргэлтийн аргын нэр',
  deliveryMethodCode: 'Хүргэлтийн аргын код',
  clientGroup: 'Хэрэглэгчийн бүлэг',
  clientGroups: 'Хэрэглэгчийн бүлгүүд',
  clientGroupEditSuccess: 'Хэрэглэгчийн бүлгийг амжилттай солисон',
  clientGroupCreateSuccess: 'Хэрэглэгчийн бүлгийг амжилттай үүсгэсэн',
  clientGroupEditTitle: 'Хэрэглэгчийн бүлгийг засварлах',
  clientGroupCreateTitle: 'Хэрэглэгчийн бүлгийг нэмэх',
  warehouse: 'Агуулах',
  warehouses: 'Агуулахууд',
  warehouseEdited: 'Агуулахыг амжилттай солисон',
  warehouseCreated: 'Агуулахыг амжилттай үүсгэсэн',
  warehouseEditTitle: 'Агуулахыг засварлах',
  warehouseCreateTitle: 'Агуулах нэмэх',
  warehouseHasNoCoords: 'Агуулахын координат байхгүй',
  warehouseSettingsEdited: 'Агуулахын тохиргоо амжилттай өөрчлөгдсөн',
  client: 'Үйлчлүүлэгч',
  shop: 'Дэлгүүр',
  shops: 'Дэлгүүрүүд',
  shopEditSuccess: 'Дэлгүүрийг амжилттай солисон',
  shopCreateSuccess: 'Дэлгүүрийг амжилттай үүсгэсэн',
  shopEditTitle: 'Дэлгүүрийг засварлах',
  shopCreateTitle: 'Дэлгүүр нэмэх',
  status: 'Статус',
  transport: 'Тээврийн хэрэгсэл',
  transportNumber: 'Машины дугаар',
  transportWeightWithUnit: 'Ачааны даац, кг',
  transportEditSuccess: 'Тээврийн хэрэгслийг амжилттай солисон',
  transportCreateSuccess: 'Тээврийн хэрэгслийг амжилттай үүсгэсэн',
  transportEditTitle: 'Тээврийн хэрэгслийг засварлах',
  transportCreateTitle: 'Тээврийн хэрэгсэл нэмэх',
  user: 'Хэрэглэгч',
  users: 'Хэрэглэгч нар',
  userEdited: 'Хэрэглэгчийг амжилттай солисон',
  userCreated: 'Хэрэглэгчийг үүсгэсэн',
  userEditTitle: 'Хэрэглэгчийг засварлах',
  userCreateTitle: 'Хэрэглэгч нэмэх',
  privacy: 'Нууцлалын бодлого',
  locations: 'Байршлууд',
  locationsList: 'Байршлуудын жагсаалт',
  locationCreateTitle: 'Байршил нэмэх',
  locationEditTitle: 'Байршлыг засварлах',
  locationWillBeSaved: 'Байршлыг хадгалах болно',
  locationCreated: 'Байршлыг нэмсэн',
  locationEdited: 'Байршлыг солив',
  locationDeleted: 'Байршлыг устгав',
  rates: 'Тарифууд',
  ratesList: 'Тарифын жагсаалт',
  rateDateTimeStart: 'Тариф эхлэх ',
  rateDateTimeEnd: 'Тариф дуусах ',
  rateEditTitle: 'Тариф засварлах',
  rateViewTitle: 'Тариф харах',
  rateCreateTitle: 'Тариф нэмэх',
  rateCopyTitle: 'Тариф хуулбарлах',
  rateCreated: 'Тариф нэмэв',
  rateEdited: 'Тариф өөрчлөв',
  rateDeleted: 'Тариф устгав',
  createRate: 'Тариф нэмэх',
  finishTheRate: 'Тариф дуусгах',
  serviceZone: 'Үйлчилгээний бүс',
  serviceZones: 'Бүсүүд',
  serviceZonesList: 'Бүсийн жагсаалт',
  withoutServiceZone: 'Үйлчилгээний бүс алга',
  serviceZoneEditTitle: 'Үйлчилгээний бүс засварлах',
  serviceZoneViewTitle: 'Үйлчилгээний бүсийг харах',
  serviceZoneCreateTitle: 'Үйлчилгээний бүсийг нэмэх',
  createServiceZone: 'Үйлчилгээний бүсийг нэмэх',
  serviceZoneWillBeSaved: 'Үйлчилгээний бүсийг хадгалах болно',
  serviceZoneCreated: 'Үйлчилгээний бүсийг нэмэв',
  serviceZoneEdited: 'Үйлчилгээний бүсийг өөрчлөв',
  serviceZoneDeleted: 'Үйлчилгээний бүсийг устгав',
  coverages: 'Газрын зургын хэсгүүд',
  coveragesList: 'Газрын зургын хэсгийн жагсаалт',
  allCoverages: 'Бүх газрын зургын хэсгүүд',
  coverageDeleted: 'Газрын зургын хэсгийг устгав',
  coverageAdded: 'Газрын зургын хэсгийг нэмэв',
  coverageEdited: 'Газрын зургын хэсгийг өөрчлөв',
  withoutCoverages: 'Газрын зургын хэсэггүй',
  coverageEditTitle: 'Газрын зургын хэсгийг засварлах',
  coverageViewTitle: 'Газрын зургын хэсгийг харах',
  coverageCreateTitle: 'Газрын зургын хэсгийг нэмэх',
  schedules: 'Хуваариуд',
  scheduleEditTitle: 'Хуваарийг засварлах',
  scheduleViewTitle: 'Хуваарийг харах',
  scheduleCreateTitle: 'Хуваарийг нэмэх',
  scheduleDeleted: 'Хуваарийг устгав',
  scheduleCopyTitle: 'Үйлчилгээний бүсийг хуулбарлах',
  sessionsRoutes: 'Ээжлийн машрутууд',
  timeslot: 'Цаг',
  deliveryTime: 'Даалгаврыг гүйцэтгэх төлөвлөсөн эсвэл бодит цаг хугацаа',
  role: 'Үүрэг',
  phone: 'Утас',
  contractor: 'Харилцагч',
  contactPerson: 'Холбоо барих хүн',
  contacts: 'Холбоо барих мэдээллүүд',
  comment: 'Коммент',
  createdDateTime: 'Үүсгэсэн огноо',
  creator: 'Үүсгэсэн',
  resolution: 'Шийдэл',
  courierSessionOnDate: '{{content}}-оос шилжилт',
  courierSessionCreated: 'Шинэ ээлжийг тохируулав',
  courierSessionEdited: 'Ээлжийг өрчилсөн',
  courierSessionDeleted: 'Ээлжийг амжилттай устгав',
  courierSessionSetup: 'Шинэ ээлж тохируулах',
  courierSessionEditTitle: 'Ээлжийг засварлах',
  courierSessionCreateTitle: 'Ээлж нэмэх',

  // navigation
  goBackward: 'Буцах',
  goIndexPage: 'Гол хуудас руу',
  goToCourierSession: 'Ээлж рүү буцах',
  goToServiceZoneCreation: 'Үйлчилгээний бүс үүсгэх рүү шилжих',
  goToRateCreation: 'Тариф үүсгэх рүү шилжих',
  goToCoverageCreation: 'Газрын зургын хэсэг үүсгэх рүү шилжих',
  goToOrderList: 'Захиалгын жагсаалт руу',
  goToRouteList: 'Маршрутын жагсаалт руу',
  goToCourierList: 'Түгээгчийн жагсаалт руу',
  goToList: 'Жагсаалт руу буцах',

  // actions
  authorize: 'Нэвтрэх',
  save: 'Хадгалах',
  calculate: 'Тооцоолох',
  saveAll: 'Бүгдийг хадгалах',
  calculateAll: 'Бугдийг тооцоолох',
  editCourier: 'Түгээгчийг засварлах',
  cancelTheRoute: 'Маршрут цуцлах',
  cancelTheRoutes: 'Маршрутуудыг цуцлах',
  add: 'Нэмэх',
  delete: 'Устгах',
  edit: 'Засварлах',
  createLocation: 'Байршил нэмэх',
  addTask: 'Даалгавар нэмэх',
  sendForLoading: 'Ачилтад илгээх',
  geocode: 'Геокодчилох',
  create: 'Үүсгэх',
  selectCourierForRoute: 'Түгээгч ба машин сонгох',
  cleanup: 'Арилгах',
  hideInactive: 'Идэвхгүй байгааг нуух',
  editWarehouseSettings: 'Агуулахын тохиргоог өөрчлөх',
  addDeliveryMethod: 'Хүргэлтийн аргыг нэмэх',
  removeLastDeliveryMethod: 'Сүүлийн тээвэрлэлтийн аргыг устгах',
  beginTheSession: 'Ээлж эхлэх',
  endTheSession: 'Ээлжийн дуусгах',
  refresh: 'Шинэчлэх',
  select: 'Сонгох',
  apply: 'Хэрэглэх',
  showCoveragesByDeliveryMethod: 'Газрын зургын хэсгүүдийг хүргэлтийн аргаар харуулах',
  applyToOtherDays: 'Долоо хоногийн бусад өдрүүдэд хэрэглэх',
  hidePast: 'Өмнөхийг нуух',

  // common
  yes: 'Тийм',
  no: 'Үгүй',
  or: 'эсвэл',
  cancel: 'Цуцлах',
  noData: 'Мэдээлэл байхгүй',
  nothingWasFound: 'Юу ч олдсонгүй',
  error: 'Алдаа',
  somethingIsWrong: 'Алдаа гарлаа',
  areYouSure: 'Та итгэлтэй байна уу?',
  sendToCourier: 'Түгээгчид явуулах',
  lastSession: 'Сүүлин ээлж',
  saved: 'Хадгалсан',
  oneMoreCoverage: 'Өөр газрын зургын хэсэг',
  addressTitle: 'Хаяг',
  extraTask: 'Нэмэлт даалгавар',
  deliveryDate: 'Хүргэлтийн огноо',
  timeSlotStart: 'Цагийн эхлэл',
  timeSlotEnd: 'Цагийн төгсгөл',
  onAddress: 'хаягаар',
  delivery: 'Хүргэлт',
  addressesLength: '{{content}}: хаягууд',
  tasksLength: '{{content}}: даалгаврууд',
  onTheList: 'Жагсаалтаар',
  onTheMap: 'Газрын зураг дээр',
  loadingAtTime: '{{content}}-д ачилт',
  comeToWarehouseAtTime: '{{content}}-д агуулах руу буцах',
  sendToLoadTime: 'Ачилтад явуулах цаг',
  weightFullness: 'Жингээр ачих',
  volumeFullness: 'Эзлэхүүнээр ачих',
  filters: 'Фильтрүүд',
  selected: 'Сонгосон',
  personalData: 'Хувийн мэдээлэл',
  authentication: 'Баталгаажуулалт',
  parameters: 'Мэдээлэл',
  mainWarehouseSettings: 'Үндсэн тохиргоо',
  problem: 'Асуудалтай',
  mondayShort: 'Даваа',
  tuesdayShort: 'Мягмар',
  wednesdayShort: 'Лхагва',
  thursdayShort: 'Пүрэв',
  fridayShort: 'Баасан',
  saturdayShort: 'Бямба',
  sundayShort: 'Ням',
  time: 'Цаг',
  period: 'Үйлчлэх хугацаа',
  number: 'Дугаар',
  atTime: '{{content}}-д',
  fromTime: '{{content}}-с',
  totalCount: 'Нийт',
  sizes: 'Хэмжээ',
  height: 'Өндөр',
  heightWithUnit: 'Өндөр, м',
  heightTemplate: 'Өн: {{content}} м',
  width: 'Өргөн',
  widthWithUnit: 'Өргөн, м',
  widthTemplate: 'Өр: {{content}} м',
  length: 'Урт',
  lengthWithUnit: 'Урт, м',
  lengthTemplate: 'У: {{content}} м',
  type: 'Төрөл',
  automatic: 'автоматаар',
  changedMyMind: 'Би бодлоо өөрчилсөн',
  priceUnitRub: 'руб.',
  averageSpeedUnitKmPerHour: 'км/цагт',
  timeUnitDayShort: 'ө.',
  timeUnitHourShort: 'ц.',
  timeUnitMinuteShort: 'мин.',
  weightUnit: 'кг',
  lengthUnit: 'м',
  forDate: '{{content}}-д',
  byOrder: 'захиалгаар',
  search: 'Хайлт',
  searchByTitle: 'Нэрээр хайх',
  searchByLocation: 'Байршлаар хайх',
  searchByNumber: 'Дугаараар хайх',
  searchByTitleOrNumber: 'Нэр эсвэл иашины дугаараар хайх',
  searchByFullNameEmailPhone: 'Овог нэр, email эсвэл утасны дугаараар хайх',
  searchByTitleAddressPhone: 'Нэр, хаяг эсвэл утсаар хайх',

  // form fields
  date: 'Огноо',
  from: 'Эхлэх',
  to: 'Дуусах',
  disabled: 'идэвхгүй',
  activity: 'Идэвхжилт',
  title: 'Нэр',
  id: 'Танигч',
  externalId: 'Гадаад систем дэх танигч',
  isActivated: 'Идэвхжүүлсэн',
  isActive: 'Идэвхтэй',
  login: 'Нэвтрэх нэр',
  password: 'Нууц үг',
  passwordConfirmation: 'Нууц үг батлах',
  sessionPlannedDate: 'Ээлж эхлэх огноо',
  fullName: 'Овог Нэр',
  coverageCreation: 'Газрын зургын хэсэг үүсгэх',
  newCoverage: 'Газрын зургын шинэ хэсэг',
  polygon: 'Полигон',
  autoDrawPolygon: 'Полигон зураглах',
  autoDraw: 'Зурах',
  selectAll: 'Бүгдийг сонгох',
  parentLocation: 'Эх байршил',
  positionOnTheMap: 'Газрын зураг дээрх байршил',
  destination: 'Очих газар',
  sender: 'Илгээгч',
  recipient: 'Хүлээн авагч',
  plannedDateTime: 'Хүргэлтийн тооцоолсон хугацаа',
  taskCancelationReason: 'Даалгаврыг цуцлах шалтгаан',
  groupTasksByCoverage: 'Газрын зургын хэсгээр бүлэглэх',
  dayStart: 'Өдрийн эхлэл',
  dayEnd: 'Өдрийн төгсгөл',
  interval: 'Интервал',
  setUpEqualIntervals: 'Тэнцүү интервалыг тохируулах',
  orderLimit: 'Захиалгын хязгаар',
  price: 'Үнэ',
  averageSpeed: 'дундаж хурд',
  addNewRow: 'Мөр нэмэх',
  shopShortName: 'Богино нэр',
  shopLegalName: 'ААН-ы нэр',
  shopCurrency: 'Ханш',
  volumeWithUnit: 'Эзлэхүүн, куб.м.',
  loadingTime: 'Ачих хугацаа',
  surname: 'Овог',
  name: 'Нэр',
  patronymic: 'Ургын овог (заавал биш)',
  userStatus: 'Хэрэглэгчийн статус',
  latitude: 'Өргөрөг',
  longitude: 'Уртраг',
  timezone: 'Цагийн бүс',
  warehouseGroupId: 'Агуулахын бүлгийн танигч',
  maxRouteTime: 'Маршрутын хамгийн их хугацаа',
  maxCourierDowntime: 'Түгээгчийн хамгийн их зогсоолт',
  maxDelayTime: 'Хамгийн их хоцрох хугацаа',
  maxLeadTime: 'Хамгийн их түрүүлэх хугацаа',
  unloadingTime: 'Буулгах хугацаа',
  guardLimit: 'Хамгаалалтын интервал',
  fullDayRoutes: 'Бүтэн өдрийг маршрутаар бөглөх',
  weight: 'Жин',
  volume: 'Эзлэхүүн',
  sum: 'Дүн',
  enterTitle: 'Нэр оруулна уу',
  enterId: 'Танигчийг оруулна уу',
  enterLogin: 'Нэвтрэх нэо оруулна уу',
  enterPassword: 'Нууц үг оруулна уу',
  enterPasswordConfirmation: 'Нууц үгийн баталгаажуулалтыг оруулна уу',
  enterTransport: 'Тээврийн хэрэгсэл сонгох',
  enterDate: 'Огноо сонгоно уу', // TODO: незаполненный перевод, творчество
  enterUserName: 'Хэрэглэгчийн нэрийг оруулна уу',
  enterPolygon: 'Газрын зураг дээр хэсгийг тэмдэглэнэ үү',
  enterLocationName: 'Байршлыг оруулна уу',
  enterLocation: 'Байршлыг сонгоно уу',
  enterWarehouse: 'Агуулах сонгоно уу',
  enterWarehouses: 'Агуулахууд сонгоно уу',
  enterLocationCenter: 'Масштабыг сонгоод байршлын төвд тэмдэглэгээг тавьна уу',
  enterSearchQueryAddress: 'Хайх хаягаа оруулна уу',
  enterTaskType: 'Ажлын төрлийг сонгоно уу',
  enterDestination: 'Очих газраа сонгоно уу',
  enterTime: 'Цаг сонгоно уу',
  enterCourier: 'Түгээгч сонгоно уу',
  enterHeight: 'Өндөрийг оруулна уу',
  enterWidth: 'Өргөнийг оруулна уу',
  enterLength: 'Уртыг оруулна уу',
  enterTaskCancelationReason: 'Шалтгааныг сонгоно уу',
  enterServiceZone: 'Үйлчилгээний бүсийг сонгоно уу',
  enterDeliveryMethod: 'Хүргэлтийн аргыг сонгоно уу',
  enterCoverages: 'Газрын зургын хэсгийг сонгоно уу',
  enterDayStart: 'Өдрийн эхлэлийг заана уу',
  enterDayEnd: 'Өдрийн төгсгөлийг заана уу',
  enterInterval: 'Интервал заана уу',
  enterOrderLimit: 'Лимит заана уу',
  enterPrice: 'Үнэ заана уу',
  enterAverageSpeed: 'Хурд оруулна уу',
  enterShopShortName: 'Богино нэр оруулна уу',
  enterShopLegalName: 'ААН нэр оруулна уу',
  enterTransportNumber: 'Машины дугаар оруулна уу',
  enterTransportWeight: 'Даац оруулна уу',
  enterLoadingTime: 'Цаг оруулна уу',
  enterSurname: 'Овог оруулна уу',
  enterName: 'Нэр оруулна уу',
  enterPatronymic: 'Ургын овог оруулна уу (заавал биш)',
  enterEmail: 'Email оруулна уу',
  enterPhone: 'Утас оруулна уу',
  enterRole: 'Дүр сонгоно уу',
  enterAddress: 'Хаяг оруулна уу',
  enterLatitude: 'Өргөргийг оруулна уу',
  enterLongitude: 'Уртрагыг оруулна уу',
  enterPoint: 'Газрын зураг дээрх цэгийг сонгоно уу',
  enterTimezone: 'Цагийн бүс сонгоно уу',
  enterWarehouseGroupId: 'Агуулахын бүлгийн танигч оруулна уу',
  enterShops: 'Дэлгүүрүүдийг сонгох',
  enterMaxRouteTime: 'Маршрутын цагийг оруулна уу',
  enterMaxDelayTime: 'Хожимдох цаг оруулна уу',
  enterMaxCourierDowntime: 'Түгээгчийн сул зогсолтыг оруулна уу',
  enterMaxLeadTime: 'Түрүүлэх хугацаа оруулна уу',
  enterUnloadingTime: 'Ачаа буулгах цагийг оруулна уу',
  enterGuardLimit: 'Хамгаалалтын интервал оруулна уу',
  enterWeight: 'Жин оруулна уу',
  enterVolume: 'Эзлэхүүн  оруулна уу',

  // other
  possiblyLate: 'Хоцорч магадгүй',
  latenessTitle: 'Хоцрох',
  lateness_one: 'Хоцрох',
  lateness_few: 'Хоцрох',
  lateness_many: 'Хоцрох',
  lateness_other: 'Хоцрох',
  possiblyOutrunning: 'Түрүүлж магадгүй',
  outrunningTitle: 'Түрүүлэх',
  outrunning_one: 'Түрүүлэх',
  outrunning_few: 'Түрүүлэх',
  outrunning_many: 'Түрүүлэх',
  outrunning_other: 'Түрүүлэх',
  task_one: 'Даалгавар',
  task_few: 'Даалгаврууд',
  task_many: 'Даалгаврууд',
  task_other: 'Даалгаврууд',
  coverage_one: 'Газрын зургын хэсэг',
  coverage_few: 'Газрын зургын хэсгүүд',
  coverage_many: 'Газрын зургын хэсгүүд',
  coverage_other: 'Газрын зургын хэсгүүд',
  address_one: 'Хаяг',
  address_few: 'Хаягууд',
  address_many: 'Хаягууд',
  address_other: 'Хаягууд',

  // validators
  passwordRequiredValidator: 'Нууц үг оруулна уу',
  loginRequiredValidator: 'Нэвтрэх нэр оруулна уу',
  transportRequiredValidator: 'Тээврийн хэрэгсэл сонгоно уу',
  requiredValidator: 'Талбарыг заавал бөглөнө үү',
  moreThanNullNumberValidator: 'Утга нь тэгээс их байх ёстой',
  positiveNumberValidator: 'Утга эерэг байх ёстой',
  integerNumberValidator: 'Утга нь бүхэл тоо байх ёстой',
  compareWithPasswordValidator: 'Нууц үг таарах ёстой',
  moreThanNullZoomValidator: 'Масштаб нь 0-ээс их байх ёстой',
  minIntervalValidator: 'Хамгийн бага зөвшөөрөгдөх интервал нь 5 минут ',
  maxIntervalValidator: 'Хамгийн дээд зөвшөөрөгдөх интервал нь 5 минут ',
  orderLimitValidator: 'Захиалгын хязгаар 0-ээс бага байж болохгүй',
  priceValidator: 'Зардал 0-ээс бага байж болохгүй',
  surnameValidator: 'Овогоо оруулна уу',
  nameValidator: 'Нэрээ оруулна уу',
  roleValidator: 'Дүрээ сонгоно уу',
  scheduleRequiredValidator: 'Цагийн хуваарь нэмэх хэрэгтэй',

  // confirm
  defaultConfirm: 'Та {{content}} хийхэд итгэлтэй байна уу?',
  deliveryMethodDeleteConfirm: 'Хүргэлтийн аргыг устгах уу?',
  clientGroupDeleteConfirm: 'Үйлчлүүлэгчийн бүлгийг устгах уу?',
  warehouseDeleteConfirm: 'Агуулахыг устгах уу?',
  shopDeleteConfirm: 'Дэлгүүр устгах уу?',
  transportDeleteConfirm: 'Тээврийн хэрэгслийг устгах уу?',
  finishTheRateConfirm: 'Тарифыг дуусгах уу? Тарифын хугацаа 1 минутын дараа дуусна.',
  leaveThePageConfirm: 'Та хуудаснаас гарахдаа итгэлтэй байна уу? Мэдээлэл хадгалагдахгүй.',
  timeSlotDeleteConfirm: 'Цагийг устгах уу?',
  courierDeleteConfirm: 'Түгээгчийг устгах уу?',
  userDeleteConfirm: 'Хэрэглэгчийг устгах уу?',
  locationDeleteConfirm: 'Байршлыг устгах уу?',
  rateDeleteConfirm: 'Тарифыг устгах уу?',
  serviceZoneDeleteConfirm: 'Үйлчилгээний бүсийг устгах уу?',
  coverageDeleteConfirm: 'Газрын зургын хэсгийг устгах уу?',
  coverageDelete: 'Газрын зургын хэсгийг устгах уу',
  coverageDeleteFromListConfirm: 'Газрын зургын хэсгийг жагсаалтаас устгах уу?',
  endCourierSessionConfirm:
    'Та ээлжээ дуусгахдаа итгэлтэй байна уу? Бүрэн бус бүх маршрутыг цуцална.',

  // notifications & phrases
  addressIsNotDefined: 'Хаяг тодорхойлогдоогүй байна',
  cannotCallTheClient: 'Түгээгч нь үйлчлүүлэгчид залгаж чадсангүй',
  courierIsNotSelected: 'Түгээгч болон тээврийн хэрэгслийг сонгоогүй',
  sessionWillBeCreateAutomatically: 'Ээлж автоматаар үүсгэгдэнэ',
  transportIsNotCompareToWarehouse: 'Агуулах нь байгаа машинтай таарахгүй байна',
  courierIsNotCompareToWarehouse: 'Агуулах нь байгаа түгээгчтэй таарахгүй байна',
  transportIsNotCompareToDeliveryMethod: 'Хүргэлтийн арга нь байгаа машинтай таарахгүй байна',
  thereIsActiveRoutesInSession: 'Ээлжид дуусаагүй маршрутууд байна',
  locationHasNoRates: 'Байршилд одоогийн тариф байхгүй',
  letsCreateRoute: 'Маршрут үүсгэхийн тулд даалгавруудыг энд чирнэ үү.',
  thereAreNoZones: 'Энд идэвхтэй үйлчилгээний бүс байхгүй',
  thereAreSomeProblems: 'Асуудал олдсон',
  noSectionFound: 'Хэсэг байхгүй',
  accessDenied: 'Хандалтыг хориглосон',
  noTasks: 'Даалгавар байхгүй',
  notDefined: 'Тодорхойлогүй',
  thereAreTasksWithNotAvailableDeliveryMethodsForTransport:
    'Машин явахгүй: хүргэлтийн боломжгүй аргуудтай даалгаварууд байна',
  thereAreTasksWithNotAvailableWarehouseForTransport:
    'Машин явахгүй: боломжгүй агуулахуудтай даалгаварууд байна',
  thereAreTasksWithNotAvailableWarehouseForCourier:
    'Түгээгч явахгүй: боломжгүй агуулахуудтай даалгаварууд байна',
  letsCreateCourierSession: '"Ээлж нэмэх" дээр дарж тухайн өдрийн түгээгчийн ээлжийн хуваарь гарна',
  warehouseCreatedWithNoCoords:
    'Уг агуулахыг геокодын алдаатай үүсгэсэн. Идэвхжүүлэх агуулахын хаягийг тодруулж өгнө үү',
  maxDelayTimeMeaning:
    'Хэрэв 0 гэж тохируулсан бол хоцролтыг зөвшөөрөөгүй. Хэрэв талбар хоосон бол ямар ч хязгаарлалт байхгүй',
  maxLeadTimeMeaning:
    'Хэрэв 0 гэж тохируулсан бол түрүүлж болохгүй. Хэрэв талбар хоосон бол ямар ч хязгаарлалт байхгүй',
  fullDayRoutesMeaning: 'Захиалгууда бүтэн өдрөөр тараах',
  searchByFullName: 'Овог нэрээр хайх',
  defaultSort: 'Эрэмбэлэх',
  ascendingAmountSort: 'Дүнг өсөх эрэмбээр',
  descendingAmountSort: 'Дүнг буурах эрэмбээр',
  ascendingWeightSort: 'Жин өсөх эрэмбээр',
  descendingWeightSort: 'Жин буурах эрэмбээр',
  ascendingVolumeSort: 'Эзлэхүүн өсөх эрэмбээр',
  descendingVolumeSort: 'Эзлэхүүн буурах эрэмбээр',
  couriersSuccessfullySend: 'Түгээгч нарыг ачилтад явуулсан',
  courierHasProcessingRoute: 'Түгээгч нь гүйцэтгэж байгаа маршруттай',
  courierHasProcessingSession: 'Түгээгч нь гүйцэтгэж байгаа ээлжтэй',
  readyToSendCouriers: 'Гарахад бэлэн түгээгч нар',
  noCouriersToSend: 'Гарахд бэлэн нэг ч түгээгч алга',
  addExtraTask: 'Нэмэлт даалгавар үүсгэх',
  taskCreationReason: 'Шалтгааныг тайлбарлана уу',
  extraTaskPreRequest: 'Түгээгч үйлчлүүлэгчтэй уулзсан уу?',
  extraTaskReasons: 'Дараах зүйлс шаардлагатай бол нэмэлт даалгавар үүсгэнэ:',
  extraTaskReason1: 'Буцаалт хийх',
  extraTaskReason2: 'Бараа хүргэх',
  extraTaskReason3: 'Бараа солих',
  extraTaskWarning: 'Ийм даалгаврыг санхүүгийн системд тусгахгүй.',
  extraTaskAction: 'Хэрэв шалтгаан нь өөр бол техникийн дэмжлэгтэй холбоо барина уу',

  deleteTasksFromRoute: 'Маршрутаас устгах',
  saleNumbers: 'Падаанууд',
  fastDelivery: 'Түргэн хүргэлт',
  inUse: 'Ашиглагдаж байна',
  accountSystems: 'Санхүүгийн программууд',
  accountSystem: 'Санхүүгийн программ',
  fullScreenMode: 'Бүтэн дэлгэцийн горим',
  zoomInTheMap: 'Газрын зураг дээр томруулах шаардлагатай',
  menu: 'Цэс',
  getAdjacentCoverages: 'Хажуугийн газрын зургын хэсгүүдийг харуулах',
  orderParameters: 'Захиалгын мэдээлэл',
  productView: 'Барааны төрөл',
  distance: 'Маршрутын төлөвлөгдсөн урт',
  bag_one: 'Уут',
  bag_few: 'Уутны',
  bag_many: 'Уутнууд',
  bag_other: 'Уутнууд',
  distanceShort: 'Маршрутын урт',
  distanceUnit: 'км',
};
