import './style.less';

import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import Navigation from 'modules/arm2/components/Navigation';
import OrdersHeader from 'modules/orders/components/OrdersHeader';
import OrdersList from 'modules/orders/components/OrdersList';
import OrdersListMap from 'modules/orders/components/OrdersListMap';
import { useStore } from 'services/store';

const OrdersListPage = () => {
  const { orders, deliveryMethods } = useStore();

  useEffect(() => {
    orders.apiStore.getOrderStatuses();
    orders.apiStore.getOrderTypes();
    deliveryMethods.getList({ current: 1, pageSize: 100 }, false);
  }, []);

  const { mapShow } = orders.filter;

  return (
    <div className="orders">
      <div className={`orders__content ${mapShow ? 'orders__content--map' : ''}`}>
        <Navigation />
        <OrdersHeader />
        <div className="orders__list">
          <OrdersList />
        </div>
      </div>
      {mapShow && (
        <div className="orders__map">
          <OrdersListMap />
        </div>
      )}
    </div>
  );
};

export default observer(OrdersListPage);
