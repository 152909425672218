import React from 'react';

import { DatePicker, Form, Space } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { dateFormat, timeFormat } from 'helpers/string';
import { TimePicker } from 'modules/common/containers/TimePicker';
import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { useStore } from 'services/store';

interface IProps {
  initialValues: IRouteDraft;
}

const TimeFormItem = ({ initialValues }: IProps) => {
  const { routeGenerator } = useStore();
  return (
    <Form.Item label={translate('routeStartTime')}>
      <Space>
        <Form.Item
          name="routeStartDate"
          initialValue={
            (routeGenerator.routeSettings &&
              routeGenerator.routeSettings.deliveryDate &&
              dayjs(routeGenerator.routeSettings.deliveryDate)) ||
            undefined
          }
          noStyle
        >
          <DatePicker
            className="rg-courier-modal__time"
            format={dateFormat.string}
            allowClear
            getPopupContainer={(el) => el}
            disabled
            needConfirm={false}
          />
        </Form.Item>
        <Form.Item
          name="time"
          rules={[{ required: true, message: translate('enterTime') }]}
          initialValue={
            (initialValues && initialValues.time && dayjs(initialValues.time)) || undefined
          }
          noStyle
        >
          <TimePicker
            className="rg-courier-modal__time"
            format={timeFormat.simple}
            placeholder={translate('enterTime')}
            allowClear
            getPopupContainer={(el) => el}
          />
        </Form.Item>
      </Space>
    </Form.Item>
  );
};

export default observer(TimeFormItem);
