import React from 'react';

import { Button, DatePicker, Tooltip } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';

import { getCurrentUTCTimezone } from 'helpers/date';
import { translate } from 'modules/localization';

interface IProps extends DatePickerProps {
  onVisibleChange?: (isVisible: boolean) => void;
}

const StartTimeRateDatepicker = ({ onVisibleChange, ...rest }: IProps) => {
  return (
    <Tooltip title={getCurrentUTCTimezone()} placement="right" onOpenChange={onVisibleChange}>
      <DatePicker
        {...rest}
        getPopupContainer={(el) => el}
        needConfirm={false}
        showNow={false}
        renderExtraFooter={() => (
          <div className="picker-footer">
            <Button
              type="link"
              className="picker-now"
              onClick={() =>
                rest.onChange(
                  dayjs().add(1, 'minute'),
                  dayjs().add(1, 'minute').format('YYYY-MM-DD[T]HH:mm:ssZ'),
                )
              }
            >
              {translate('now')}
            </Button>
          </div>
        )}
      />
    </Tooltip>
  );
};

export default StartTimeRateDatepicker;
