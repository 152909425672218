import React, { ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import OrderTask from 'modules/orders/components/OrderTask';
import { IOrder } from 'modules/orders/models/types';
import { ITask } from 'modules/tasks/models/types';

interface IProps {
  order: IOrder;
  orderUpdate: () => Promise<void>;
  isCanBeAddedNewTask: boolean;
  openNewTaskModal: () => void;
  isSupport?: boolean;
}

const OrderTaskList = ({
  order,
  orderUpdate,
  openNewTaskModal,
  isCanBeAddedNewTask,
  isSupport,
}: IProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    order.tasks &&
    order.tasks.map(
      (task: ITask): ReactNode => (
        <OrderTask
          key={task.guid}
          task={task}
          orderUpdate={orderUpdate}
          isCanBeAddedNewTask={isCanBeAddedNewTask}
          openNewTaskModal={openNewTaskModal}
          order={order}
          isSupport={isSupport}
        />
      ),
    )
  );
};

export default observer(OrderTaskList);
