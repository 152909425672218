import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import Navigation from 'modules/arm2/components/Navigation';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import { initialRouteData } from 'modules/routes/models/initial';
import { IRoute } from 'modules/routes/models/types';
import { useStore } from 'services/store';

import RouteChangeStatus from './RouteChangeStatus';
import RouteStatus from './RouteStatus';
import RouteInfo from '../RouteInfo';
import RoutesMap from '../RoutesMap';

interface IParams {
  entityGuid: string;
}

interface IProps {
  baseUrl: string;
}

const UPDATE_PERIOD = 60000;

const RouteViewPage = ({ baseUrl }: IProps) => {
  const { routes, router, core } = useStore();

  const { entityGuid } = useParams<IParams>();
  const [route, setRoute] = useState<IRoute>(initialRouteData);
  const routeUpdate = (): Promise<void> => routes.apiStore.getRoute(entityGuid).then(setRoute);

  const [period, setPeriod] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPeriod((p) => p + 1);
    }, UPDATE_PERIOD);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (route && entityGuid && route.status !== 'canceled' && route.status !== 'complete') {
      routeUpdate();
    }
  }, [period]);

  useEffect(() => {
    if (entityGuid) {
      routeUpdate();
    }
  }, [entityGuid]);

  const handleBackLink = (): void => router.push({ pathname: baseUrl });

  return (
    <div className="route">
      <div className="route__main">
        <div className="route__header">
          <Navigation />
          <Button type="link" className="route__back-link" onClick={handleBackLink}>
            <ArrowLeftOutlined /> {translate('goToRouteList')}
          </Button>
          <div className="route__title">
            <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
              {translate('route')} #{route.number}
            </Title>
            <RouteStatus route={route} />
          </div>
          <RouteChangeStatus route={route} routeUpdate={routeUpdate} />
        </div>
        {core.isMobile && (
          <div className="route__map">
            {!routes.isLoadingRoute && route.start_warehouse !== null && (
              <RoutesMap route={route} />
            )}
          </div>
        )}
        <RouteInfo route={route} routeUpdate={routeUpdate} />
      </div>
      {!core.isMobile && (
        <div className="route__map">
          {!routes.isLoadingRoute && route.start_warehouse !== null && <RoutesMap route={route} />}
        </div>
      )}
    </div>
  );
};

export default observer(RouteViewPage);
