import dayjs from 'dayjs';

import { dateFormat, timeFormat } from 'helpers/string';
import { IRatesForm, IRatesFormParams } from 'modules/rates/models/types';

export const formatOutput = `${dateFormat.string} ${timeFormat.simple}`;

export const prepareData = (formData: Partial<IRatesForm>): IRatesFormParams => {
  const {
    name,
    schedule_guid,
    date_time_end,
    date_time_start,
    delivery_method_guid,
    service_zone_guid,
  } = formData;
  return {
    name: name && name.trim(),
    date_time_end: date_time_end && dayjs(date_time_end).format('YYYY-MM-DD[T]HH:mm:ssZ'),
    date_time_start: date_time_start && dayjs(date_time_start).format('YYYY-MM-DD[T]HH:mm:ssZ'),
    delivery_method_guid,
    schedule_guid,
    service_zone_guid,
  };
};
