import React, { useCallback, useState } from 'react';

import { Form, Checkbox, Space, DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';

import { dateFormat, timeFormat } from 'helpers/string';
import { TimePicker } from 'modules/common/containers/TimePicker';
import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';

interface IProps {
  initialValues: IRouteDraft;
  deltaPlus?: number;
  deltaMinus?: number;
  resetValue?: () => void;
  currentPlanDate?: Dayjs;
}

const PlanUploadTimeFormItem = ({
  initialValues,
  deltaPlus,
  deltaMinus,
  resetValue,
  currentPlanDate,
}: IProps) => {
  const [isFieldActive, setFieldActive] = useState(initialValues.isPlanUploadTimeActive);
  const toggleFieldActive = useCallback(() => {
    resetValue();
    setFieldActive((isFieldActive) => !isFieldActive);
  }, []);

  const disabledHours = () => [0, 1, 2, 3, 4];
  const disabledTime = () => ({
    disabledHours,
  });

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const dayPlus = Number.isInteger(deltaPlus) ? deltaPlus : 1;
    const dayMinus = Number.isInteger(deltaMinus) ? deltaMinus : 1;

    const currentDay = currentPlanDate ? currentPlanDate : dayjs();
    const currentDayPlus = currentDay.add(dayPlus, 'day').endOf('day');
    const currentDayMinus = currentDay.add(-dayMinus, 'day').endOf('day');

    return (
      current && (current.endOf('day') < currentDayMinus || current.endOf('day') > currentDayPlus)
    );
  };

  return (
    <>
      {isFieldActive && (
        <Form.Item label={translate('planUploadTime')}>
          <Space>
            <Form.Item
              name="planUploadDate"
              rules={[{ required: true, message: translate('enterDate') }]}
              initialValue={
                (initialValues &&
                  initialValues.planUploadTime &&
                  dayjs(initialValues.planUploadTime)) ||
                undefined
              }
              noStyle
            >
              <DatePicker
                className="rg-courier-modal__plan-upload"
                format={dateFormat.string}
                hideDisabledOptions
                disabledDate={disabledDate}
                placeholder={translate('enterDate')}
                allowClear
                getPopupContainer={(el) => el}
                showNow={false}
                needConfirm={false}
              />
            </Form.Item>
            <Form.Item
              name="planUploadTime"
              rules={[{ required: true, message: translate('enterTime') }]}
              initialValue={
                (initialValues &&
                  initialValues.planUploadTime &&
                  dayjs(initialValues.planUploadTime)) ||
                undefined
              }
              noStyle
            >
              <TimePicker
                className="rg-courier-modal__plan-upload"
                hideDisabledOptions
                disabledTime={disabledTime}
                minuteStep={30}
                format={timeFormat.simple}
                placeholder={translate('enterTime')}
                allowClear
                getPopupContainer={(el) => el}
              />
            </Form.Item>
          </Space>
        </Form.Item>
      )}
      <Form.Item
        name="isPlanUploadTimeActive"
        initialValue={initialValues && !initialValues.isPlanUploadTimeActive}
        valuePropName="checked"
      >
        <Checkbox onChange={toggleFieldActive}>{translate('noShipmentFromTheWarehouse')}</Checkbox>
      </Form.Item>
    </>
  );
};

export default PlanUploadTimeFormItem;
