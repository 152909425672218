import React from 'react';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import { getCommentProp } from 'modules/orders/helpers/propHelper';
import { IOrder } from 'modules/orders/models/types';

interface IProps {
  order: IOrder;
}

const OrderComment = ({ order }: IProps) => {
  return <InfoFact header={translate('comment')}>{order[getCommentProp(order)]}</InfoFact>;
};

export default OrderComment;
