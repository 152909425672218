import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import Form from 'modules/directory/containers/Form';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import { getDataToSet, getFields, prepareData } from './helpers';

interface IProps {
  baseUrl: string;
}

const ShopsForm = ({ baseUrl }: IProps) => {
  const { shops, core } = useStore();

  const isView = !core.permissions['shops.update'];
  const getFormFields = getFields(isView);

  return (
    <Form
      baseUrl={baseUrl}
      actionCreate={shops.create}
      actionEdit={shops.edit}
      actionGet={shops.get}
      getDataToSet={getDataToSet}
      getFields={getFormFields}
      prepareData={prepareData}
      message={{
        editSuccess: translate('shopEditSuccess'),
        createSuccess: translate('shopCreateSuccess'),
        editTitle: translate('shopEditTitle'),
        createTitle: translate('shopCreateTitle'),
      }}
      isPermittedSubmit={core.permissions['shops.create'] && core.permissions['shops.update']}
      isLoading={shops.isLoadingForm}
    />
  );
};

export default observer(ShopsForm);
