import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { getFieldDataList } from 'helpers/form';
import { timeFormat } from 'helpers/string';
import { PageTitle } from 'modules/common/components/HeaderMobile';
import Title from 'modules/common/components/Title';
import Loader from 'modules/common/containers/Loader';
import { ViewNotification } from 'modules/common/containers/Notify';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import MapCollapse from 'modules/map/containers/MapCollapse';
import EndTimeRateDatepicker from 'modules/rates/components/RatesForm/EndTimeRateDatepicker';
import { formatOutput, prepareData } from 'modules/rates/components/RatesForm/helpers';
import StartTimeRateDatepicker from 'modules/rates/components/RatesForm/StartTimeRateDatepicker';
import RatesMap from 'modules/rates/components/RatesMap';
import { IRatesForm } from 'modules/rates/models/types';
import SchedulesForm from 'modules/schedules/components/SchedulesForm';
import ServiceZonesSelect from 'modules/service-zones/components/ServiceZonesSelect';
import { useStore } from 'services/store';

interface IParams {
  rateGuid: string;
}

interface IProps {
  baseUrl: string;
  actionName: string;
}

const RatesForm = ({ baseUrl, actionName }: IProps) => {
  const { rates, router, core, schedules, locations } = useStore();

  const [showModal, setShowModal] = useState(false);
  const [isRateStart, setIsRateStart] = useState(false);
  const [isRateEnd, setIsRateEnd] = useState(false);
  const [scheduleGuid, setScheduleGuid] = useState<string>(null);

  const { rateGuid } = useParams<IParams>();
  const location = useLocation();
  const [form] = Form.useForm<IRatesForm>();

  useEffect(() => {
    if (rateGuid) {
      rates.apiStore
        .getRate(rateGuid)
        .then((schedule) => {
          const data = {
            name: schedule.name,
            service_zone_guid: schedule.service_zone_guid,
            delivery_method_guid: schedule.delivery_method_guid,
            date_time_start: actionName === 'edit' && dayjs(schedule.date_time_start),
            date_time_end: actionName === 'edit' && dayjs(schedule.date_time_end),
          };
          form.setFieldsValue(data);
          setScheduleGuid(schedule.schedule_guid);
          actionName === 'edit' && setIsRateStart(dayjs(schedule.date_time_start) < dayjs());
          actionName === 'edit' && setIsRateEnd(dayjs(schedule.date_time_end) < dayjs());
        })
        .catch(() => router.push({ pathname: baseUrl, search: router.location.search }));
    }
  }, [rateGuid]);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    form.setFieldsValue({ service_zone_guid: rates.masterData.serviceZoneGuid });
  }, [rates.masterData.serviceZoneGuid]);

  const handleReset = (): void => {
    rates.resetMasterData();
    router.push({ pathname: baseUrl, search: location.search });
  };

  const handleError = (values) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const handleSubmit = (values: Partial<IRatesForm>): void => {
    if (!scheduleGuid) {
      ViewNotification.error({ message: translate('scheduleRequiredValidator') });
    } else {
      if (actionName === 'create') {
        rates.apiStore
          .createRate(prepareData({ ...values, schedule_guid: scheduleGuid }))
          .then(() => {
            rates.resetMasterData();
            router.push({
              pathname: baseUrl,
              search: router.location.search,
            });
          })
          .catch(handleError(values));
      }

      if (actionName === 'copy') {
        rates.apiStore
          .createRate(prepareData({ ...values, schedule_guid: scheduleGuid }))
          .then(() =>
            router.push({
              pathname: baseUrl,
              search: router.location.search,
            }),
          )
          .catch(handleError(values));
      }

      if (actionName === 'edit') {
        if (isRateStart) {
          const params = {
            date_time_end: values.date_time_end,
          };
          rates.apiStore
            .editRate(rateGuid, prepareData(params))
            .then(() =>
              router.push({
                pathname: baseUrl,
                search: router.location.search,
              }),
            )
            .catch(handleError(values));
        } else {
          rates.apiStore
            .editRate(rateGuid, prepareData(values))
            .then(() =>
              router.push({
                pathname: baseUrl,
                search: router.location.search,
              }),
            )
            .catch(handleError(values));
        }
      }
    }
  };

  const endRate = (): void => {
    const params: Partial<IRatesForm> = {
      date_time_end: dayjs().add(1, 'minute'),
    };
    rates.apiStore.editRate(rateGuid, prepareData(params)).then(() =>
      router.push({
        pathname: baseUrl,
        search: router.location.search,
      }),
    );
  };

  const [isServiceZonesTooltipVisible, setServiceZonesTooltipVisible] = useState(false);
  const onTimeTooltipVisibleChange = (isVisible: boolean): void =>
    setServiceZonesTooltipVisible(isVisible);

  const isView = !core.permissions['tariffs.update'];

  return (
    <div className="locations-layout">
      <div className="locations-layout__content locations-layout__content--rates">
        {core.isMobile ? (
          <PageTitle>
            <a onClick={handleReset}>
              <ArrowLeftOutlined />{' '}
              {`${
                locations?.viewLocation?.name ? locations.viewLocation.name + ' — ' : ''
              } ${translate('ratesList')}`}
            </a>
          </PageTitle>
        ) : null}
        <Form className="rates__form" layout="vertical" form={form} onFinish={handleSubmit}>
          <div className="rates__form-body">
            <div className="rates__form-header">
              {!core.isMobile && (
                <Button type="link" className="rates__form-back-link" onClick={handleReset}>
                  <ArrowLeftOutlined />{' '}
                  {`${
                    locations?.viewLocation?.name ? locations.viewLocation.name + ' — ' : ''
                  } ${translate('ratesList')}`}
                </Button>
              )}
              <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
                {actionName === 'edit' && core.permissions['tariffs.update']
                  ? translate('rateEditTitle')
                  : null}
                {actionName === 'edit' &&
                  !core.permissions['tariffs.update'] &&
                  translate('rateViewTitle')}
                {actionName === 'create' && translate('rateCreateTitle')}
                {actionName === 'copy' && translate('rateCopyTitle')}
              </Title>
            </div>
            {core.isMobile ? (
              <MapCollapse>
                <RatesMap location={locations.viewLocation} />
              </MapCollapse>
            ) : null}
            <div className="rates__form-fields">
              <Form.Item
                label={translate('title')}
                name="name"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <Input placeholder={translate('enterTitle')} disabled={isRateStart || isView} />
              </Form.Item>
              <Form.Item
                label={translate('serviceZone')}
                name="service_zone_guid"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <ServiceZonesSelect
                  placeholder={translate('enterServiceZone')}
                  isTooltipVisible={isServiceZonesTooltipVisible}
                  disabled={isRateStart || isView}
                  withTooltip
                />
              </Form.Item>
              <Form.Item
                label={translate('deliveryMethod')}
                name="delivery_method_guid"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <DeliveryMethodsSelect disabled={isRateStart || isView} />
              </Form.Item>
              <Form.Item
                label={translate('rateDateTimeStart')}
                name="date_time_start"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <StartTimeRateDatepicker
                  showTime={{ format: timeFormat.simple }}
                  format={formatOutput}
                  disabled={isRateStart || isView}
                  onVisibleChange={onTimeTooltipVisibleChange}
                  setFieldValue={form.setFieldsValue}
                />
              </Form.Item>
              <Form.Item
                label={translate('rateDateTimeEnd')}
                name="date_time_end"
                rules={[{ required: true, message: translate('requiredValidator') }]}
              >
                <EndTimeRateDatepicker
                  showTime={{
                    format: timeFormat.simple,
                    defaultValue: dayjs('23:59:00', timeFormat.full),
                  }}
                  format={formatOutput}
                  isView={isView ? false : isRateStart && !isRateEnd}
                  endRate={endRate}
                  onVisibleChange={onTimeTooltipVisibleChange}
                  disabled={isRateEnd || isView}
                  disabledDate={(current: Dayjs): boolean => {
                    const newCurrent = current.clone();
                    const diff = dayjs.duration(dayjs().endOf('day').diff(newCurrent.endOf('day')));
                    return current && diff.asDays() > 1;
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={(): void => setShowModal(true)}
                  className="rates__form-edit-schedule-button"
                >
                  {scheduleGuid
                    ? isRateStart
                      ? translate('scheduleViewTitle')
                      : translate('scheduleEditTitle')
                    : translate('scheduleCreateTitle')}
                </Button>
              </Form.Item>
            </div>
          </div>

          <Spin
            indicator={<Loader show />}
            spinning={rates.loadingForm}
            wrapperClassName="rates__form-footer"
          >
            <Button htmlType="button" key="cancel" onClick={handleReset}>
              {translate('cancel')}
            </Button>

            {actionName === 'edit' && core.permissions['tariffs.update'] ? (
              <Button htmlType="submit" key="save" type="primary">
                {translate('save')}
              </Button>
            ) : null}
            {actionName === 'copy' && core.permissions['tariffs.create'] ? (
              <Button htmlType="submit" key="save" type="primary">
                {translate('save')}
              </Button>
            ) : null}
            {actionName === 'create' && core.permissions['tariffs.create'] ? (
              <Button htmlType="submit" key="save" type="primary">
                {translate('add')}
              </Button>
            ) : null}
          </Spin>
        </Form>
        <Modal
          open={showModal}
          onOk={(): void => setShowModal(false)}
          onCancel={(): void => {
            schedules.resetFormData();
            setShowModal(false);
          }}
          width="90%"
          footer={null}
          destroyOnClose
          className="rates__form-schedule"
        >
          <SchedulesForm
            scheduleGuid={scheduleGuid}
            actionName={actionName}
            onCancel={(): void => setShowModal(false)}
            onOk={setScheduleGuid}
            rateName={form.getFieldValue(['name'])}
          />
        </Modal>
      </div>
      {!core.isMobile && (
        <div className="locations-layout__map">
          <RatesMap location={locations.viewLocation} />
        </div>
      )}
    </div>
  );
};

export default observer(RatesForm);
