import './style.less';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import React, { useState } from 'react';

import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import { dateFormat } from 'helpers/string';
import { ISearchResult } from 'modules/map/containers/Search';
import RatesInfoByPoint from 'modules/rates/components/RatesPointInfo';

interface IProps {
  result: ISearchResult;
}

const SearchResultForm = ({ result }: IProps) => {
  const today = dayjs();
  const [date, setDate] = useState(today);

  const onDateChange = (newDate: Dayjs) => {
    setDate(newDate);
  };

  return (
    <>
      <div className="search-result-modal__date-address-wrap">
        <DatePicker
          format={`${dateFormat.string}`}
          defaultValue={today}
          allowClear={false}
          getPopupContainer={(el) => el}
          onChange={onDateChange}
          className="search-result-modal__datepicker"
          needConfirm={false}
        />
        <div className="search-result-modal__address">
          <div>{result.label}</div>
          <div className="search-result-modal__coords">
            {result.y}, {result.x}
          </div>
        </div>
      </div>

      <RatesInfoByPoint
        lat={result.raw.lat}
        long={result.raw.long}
        date={date.format(dateFormat.search)}
      />
    </>
  );
};

export default observer(SearchResultForm);
