import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import LangControl from 'modules/common/components/LangControl';
import { CustomIcon } from 'modules/common/containers/CustomIcon';
import { SECTION_DATA } from 'modules/common/containers/RouterSection/constants';
import { isPermitted } from 'modules/core/helpers/permissions';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const Header = () => {
  const { core } = useStore();

  const endMove = () => {
    document.body.style.cursor = 'default';
    document.body.style.userSelect = 'default';
    window.removeEventListener('mousemove', whileMove);
    window.removeEventListener('mouseup', endMove);
  };

  const whileMove = (event) => {
    core.toggleHeader(event.clientX > 100);
  };

  const handleMouseDown = (event) => {
    event.stopPropagation();
    document.body.style.cursor = 'ew-resize';
    document.body.style.userSelect = 'none';
    window.addEventListener('mousemove', whileMove);
    window.addEventListener('mouseup', endMove);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    core.logout();
  };

  const checkAccess = (permissions?: string[] | string): boolean => {
    return isPermitted(core.permissions, permissions);
  };

  return (
    <div className={`header ${core.isHeaderOpened ? 'header--opened' : ''}`}>
      <div className="header__close" onMouseDown={handleMouseDown} />

      <div className="header__item">
        <NavLink className="header__title header__title--logo" to="/" exact>
          <CustomIcon className="header__title-icon" type="md-logo" />
          {core.isHeaderOpened && <span className="header__title-text">Mag Delivery</span>}
        </NavLink>
      </div>

      {checkAccess(SECTION_DATA.arm2.access) && (
        <div className="header__item">
          <NavLink to="/arm2" className="header__title" title={translate('planning')}>
            <CustomIcon className="header__title-icon" type="arm" />
            {core.isHeaderOpened && (
              <span className="header__title-text">{translate('planning')}</span>
            )}
          </NavLink>
        </div>
      )}
      {checkAccess(SECTION_DATA.geo.access) && (
        <div className="header__item">
          <NavLink to="/geo/locations" className="header__title" title={translate('tariffing')}>
            <CustomIcon className="header__title-icon" type="geo" />
            {core.isHeaderOpened && (
              <span className="header__title-text">{translate('tariffing')}</span>
            )}
          </NavLink>
        </div>
      )}
      {checkAccess(SECTION_DATA.directories.access) && (
        <div className="header__item">
          <NavLink to="/directories" className="header__title" title={translate('directory')}>
            <CustomIcon className="header__title-icon" type="dir" />
            {core.isHeaderOpened && (
              <span className="header__title-text">{translate('directory')}</span>
            )}
          </NavLink>
        </div>
      )}

      {checkAccess(SECTION_DATA.support.access) && (
        <div className="header__item">
          <NavLink to="/support" className="header__title" title={translate('support')}>
            <CustomIcon className="header__title-icon" type="support" />
            {core.isHeaderOpened && (
              <span className="header__title-text">{translate('support')}</span>
            )}
          </NavLink>
        </div>
      )}

      <div className="header__item header__item--bottom">
        <LangControl />

        {core.accountData && (
          <div className="header__item header__item--logout">
            <a
              href="#"
              onClick={handleLogout}
              className="header__title"
              title={`${core.accountData.name} ${core.accountData.surname}`}
            >
              <CustomIcon className="header__title-icon" type="logout" />
              {core.isHeaderOpened && (
                <span className="header__title-text">{translate('logout')}</span>
              )}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Header);
