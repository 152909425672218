import './style.less';

import React, { useEffect } from 'react';

import { Button, Checkbox, CheckboxProps, Empty, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import emptyLines from 'img/empty-lines.svg?react';
import { translate } from 'modules/localization';
import LineCheckbox from 'modules/routeGenerator/components/common/LinesModal/LineCheckbox';
import { useStore } from 'services/store';

interface IProps {
  isModalOpened: boolean;
  onCancel: () => void;
}

const LinesModal = ({ isModalOpened, onCancel }: IProps) => {
  const { routeGenerator } = useStore();

  const hasLines = routeGenerator.linesListFilter.length > 0;
  const checkAll =
    routeGenerator.linesListFilter.length === routeGenerator.getLinesListIsCheckedLength;
  const indeterminate =
    routeGenerator.getLinesListIsCheckedLength > 0 &&
    routeGenerator.getLinesListIsCheckedLength < routeGenerator.linesListFilter.length;

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    routeGenerator.setSelectAllLines(e.target.checked);
  };

  const addToRoutes = () => {
    onCancel();
    routeGenerator.addAutoRoutes();
  };

  useEffect(() => {
    isModalOpened && routeGenerator.apiStore.getLines();
  }, [isModalOpened]);

  const renderFooter = () => (
    <>
      <Button htmlType="button" key="cancel" onClick={onCancel}>
        {translate('cancel')}
      </Button>
      <Button
        htmlType="submit"
        key="save"
        type="primary"
        disabled={!(indeterminate || checkAll)}
        onClick={addToRoutes}
      >
        {translate('addToRoutes')}
      </Button>
    </>
  );

  return (
    <Modal
      title={translate('selectLine')}
      open={isModalOpened}
      className="rg-line-modal"
      onOk={routeGenerator.addAutoRoutes}
      onCancel={onCancel}
      loading={routeGenerator.isLoadingLinesList}
      width={564}
      footer={hasLines ? renderFooter : null}
    >
      {hasLines ? (
        <>
          <div className="rg-line-modal__select-all">
            <Checkbox onChange={onCheckAllChange} checked={checkAll} indeterminate={indeterminate}>
              {translate('selectAll')}
            </Checkbox>
          </div>
          <div className="rg-line-modal__lines">
            {routeGenerator.linesListFilter.map((line) => (
              <LineCheckbox line={line} key={line.guid} />
            ))}
          </div>
        </>
      ) : (
        <Empty
          className="rg-line-modal__empty"
          image={`${emptyLines}`}
          imageStyle={{
            height: 152,
          }}
          description={translate('noSuitableDestinationsToAddToTheRoute')}
        >
          <Link to={'/arm2/lines'} target="_blank">
            <Button type="primary">{translate('goToLine')}</Button>
          </Link>
        </Empty>
      )}
    </Modal>
  );
};

export default observer(LinesModal);
