import React from 'react';

import { observer } from 'mobx-react-lite';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';
import { useStore } from 'services/store';

interface IProps {
  order: IOrder;
}

const OrderDeliveryMethod = ({ order }: IProps) => {
  const { deliveryMethods } = useStore();

  const deliveryMethod = deliveryMethods.listAll.find((s) => s.guid === order.delivery_method_guid);

  return (
    <InfoFact header={translate('deliveryMethod')}>
      {deliveryMethod ? deliveryMethod.name : null}
    </InfoFact>
  );
};

export default observer(OrderDeliveryMethod);
