import React, { ReactNode, forwardRef } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import timezones, { TimeZone } from 'timezones-list';

import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';

const TimezoneSelect = forwardRef<BaseSelectRef, SelectProps>(({ onChange, ...rest }, ref) => {
  const onChangeValue = (value, option): void => {
    onChange(value, option);
  };

  const renderOption = (option: TimeZone): ReactNode => {
    if (option) {
      return (
        <Select.Option key={option.label} value={option.tzCode}>
          {`${option.tzCode} (${option.utc})`}
        </Select.Option>
      );
    }

    return null;
  };

  return (
    <Select
      ref={ref}
      allowClear
      getPopupContainer={(el) => el}
      onChange={onChangeValue}
      placeholder={translate('enterTimezone')}
      showSearch
      filterOption={selectFilter}
      {...rest}
      {...DEFAULT_SELECT_PROPS}
    >
      {timezones.map(renderOption)}
    </Select>
  );
});

export default observer(TimezoneSelect);
