import './style.less';

import React, { useEffect } from 'react';

import { Empty } from 'antd';
import { observer } from 'mobx-react-lite';

import empty from 'img/empty-support.svg?react';
import Title from 'modules/common/components/Title';
import Loader from 'modules/common/containers/Loader';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import OrderResult from '../OrderResult';
import RouteResult from '../RouteResult';
import SupportSearch from '../SupportSearch';

const SupportResults = () => {
  const { orders, tasks, shops, deliveryMethods, clientGroups, support, routes } = useStore();

  useEffect(() => {
    tasks.apiStore.getTasksTypes();
    orders.apiStore.getOrderStatuses();
    orders.apiStore.getOrderTypes();
    deliveryMethods.getAll();
    shops.getAll();
    clientGroups.getAll();
    tasks.apiStore.getTasksStatuses();
    tasks.apiStore.getTasksResolutions();
    tasks.apiStore.getTasksProblems();
    routes.apiStore.getRoutesStatuses();
  }, []);

  return (
    <div className="support-result">
      <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD} className="support-result__title">
        {translate('supportTitle')}
      </Title>
      <div className="support-result__search">
        <SupportSearch />
      </div>

      {support.routesList &&
        support.routesList.map((route) => <RouteResult key={route.guid} route={route} />)}
      {support.orderList &&
        support.orderList.map((order) => (
          <OrderResult key={order.guid} order={order} isSingle={support.orderList.length === 1} />
        ))}

      {support.isLoading && (
        <div className="support-result__loader">
          <Loader show size={7} />
        </div>
      )}

      {!support.isLoading && !support.routesList?.length && !support.orderList?.length && (
        <Empty
          className="support-result__empty-list"
          image={`${empty}`}
          imageStyle={{
            height: 152,
          }}
          description={
            <>
              <Title size={Title.SIZE.H2} weight={Title.WEIGHT.SEMIBOLD}>
                {translate('supportEmptyTitle')}
              </Title>
              <p className="support-result__empty-subtitle">{translate('supportEmptySubtitle')}</p>
            </>
          }
        />
      )}
    </div>
  );
};

export default observer(SupportResults);
