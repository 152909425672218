import Api from 'api/Api';

import {
  ITasksFilter,
  ITasksListGetResponse,
  ITasksProblemsGetResponse,
  ITasksResolutionsGetResponse,
  ITasksStatusChangeResponse,
  ITasksStatusGetResponse,
  ITasksTimeChangeResponse,
  ITasksTypesGetResponse,
  ITaskGetResponse,
  ITaskResendResponse,
} from '../models/types';

export default class TasksApi {
  static getTasksList(
    filter: ITasksFilter,
    signal?: AbortSignal,
    customHeaders?,
  ): Promise<ITasksListGetResponse> {
    const dateList =
      filter.deliveryDateList && filter.deliveryDateList.length > 0
        ? filter.deliveryDateList
        : filter.deliveryDate && [filter.deliveryDate];
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      q: filter.search,
      delivery_date_list: dateList,
      shop_guid_list: filter.shop,
      warehouse_guid_list: filter.warehouses,
      time_slot_start: filter.from,
      time_slot_end: filter.to,
      type_list: filter.type,
      status_list: filter.status,
      delivery_method_guid_list: filter.deliveryMethod,
      is_problem: filter.isProblem,
    };
    return Api.get('/api/v1/tasks', query, signal, customHeaders);
  }

  static getTasksStatuses(): Promise<ITasksStatusGetResponse> {
    return Api.get('/api/v1/dictionaries/task-statuses');
  }

  static getTasksResolutions(): Promise<ITasksResolutionsGetResponse> {
    return Api.get('/api/v1/dictionaries/task-resolutions');
  }

  static getTasksTypes(): Promise<ITasksTypesGetResponse> {
    return Api.get('/api/v1/dictionaries/task-types');
  }

  static getTasksProblems(): Promise<ITasksProblemsGetResponse> {
    return Api.get('/api/v1/dictionaries/task-problems');
  }

  static changeTaskStatus(
    taskGuid: string,
    status: string,
    resolution: string,
  ): Promise<ITasksStatusChangeResponse> {
    const data = {
      status,
      resolution,
    };
    return Api.patch(data, `/api/v1/tasks/${taskGuid}/status`);
  }

  static changeTaskTimeSlot(
    taskGuid: string,
    delivery_date: string,
    time_slot_start: string,
    time_slot_end: string,
  ): Promise<ITasksTimeChangeResponse> {
    const data = {
      delivery_date,
      time_slot_end,
      time_slot_start,
    };
    return Api.patch(data, `/api/v1/tasks/${taskGuid}/delivery-slot`);
  }

  static getTask(guid: string): Promise<ITaskGetResponse> {
    return Api.get(`/api/v1/tasks/${guid}`);
  }

  static resendTask(guid: string): Promise<ITaskResendResponse> {
    return Api.post({}, `/api/v1/support/send_to_account_system/task/${guid}`);
  }
}
