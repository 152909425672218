import React from 'react';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { ProductViewIcon } from 'modules/common/components/ProductViewIcon';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';

interface IProps {
  order: IOrder;
}

const OrderProductView = ({ order }: IProps) => {
  return (
    <InfoFact header={translate('productView')}>
      {order.transport_param &&
      order.transport_param.specialty &&
      Array.isArray(order.transport_param.specialty) ? (
        <ProductViewIcon productTypes={order.transport_param.specialty} />
      ) : null}
    </InfoFact>
  );
};

export default OrderProductView;
