import './style.less';

import React, { useEffect, useState } from 'react';

import { Collapse, CollapseProps } from 'antd';

import Title from 'modules/common/components/Title';
import { CustomIcon } from 'modules/common/containers/CustomIcon';
import { translate } from 'modules/localization';
import OrderInfo from 'modules/orders/components/OrderInfo';
import { IOrder } from 'modules/orders/models/types';
import SupportOrderButtons from 'modules/supportFunctional/components/SupportButtons/SupportOrderButtons';
import { useStore } from 'services/store';

interface IProps {
  order: IOrder;
  isSingle: boolean;
}

const OrderResult = ({ order: initialOrderData, isSingle }: IProps) => {
  const { orders, core } = useStore();

  const [order, setOrder] = useState<IOrder>(initialOrderData);
  const orderUpdate = (): Promise<void> =>
    orders.apiStore.getOrder(initialOrderData.guid).then(setOrder);

  const orderItem: CollapseProps['items'] = [
    {
      key: order.guid,
      label: (
        <div className="order-result__title-wrap">
          <Title
            size={Title.SIZE.H3}
            weight={Title.WEIGHT.SEMIBOLD}
            className="order-result__title"
          >
            {translate('order')} #{order.number}
            <CustomIcon className="order__title-icon" type="icon-down" />
          </Title>
        </div>
      ),
      extra: core.permissions['support.scenarios.execute'] &&
        order.available_support_scenario?.length && (
          <SupportOrderButtons order={order} orderUpdate={orderUpdate} />
        ),
      children: <OrderInfo order={order} orderUpdate={orderUpdate} isSupport />,
      className: 'order-result__order-body',
    },
  ];

  useEffect(() => {
    if (isSingle) {
      orderUpdate();
    }
  }, []);

  return (
    <Collapse
      bordered={false}
      expandIcon={() => null}
      className="order-result__order-head"
      items={orderItem}
      onChange={([id]) => id && orderUpdate()}
      defaultActiveKey={isSingle && order.guid}
      collapsible="header"
    />
  );
};

export default OrderResult;
