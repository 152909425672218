import React from 'react';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';

interface IProps {
  order: IOrder;
}

const OrderPayment = ({ order }: IProps) => {
  const PaymentStatus = () => {
    if (
      !order?.meta?.payment ||
      order?.meta?.payment?.state === null ||
      order?.meta?.payment?.code === null
    ) {
      return <>&mdash;</>;
    }

    return (
      <div className="order-info__payment">
        {order?.meta?.payment?.state ? (
          <span className="order-info__payment-paid">{translate('paymentPaid')}</span>
        ) : (
          <span className="order-info__payment-notpaid">{translate('paymentNotPaid')}</span>
        )}
        <p className="order-info__payment-name">{order?.meta?.payment?.web_name}</p>
      </div>
    );
  };

  return (
    <InfoFact header={translate('paymentStatus')}>
      <PaymentStatus />
    </InfoFact>
  );
};

export default OrderPayment;
