import { createContext, useContext } from 'react';

import { RouterStore } from 'mobx-react-router';

import { AccountSystemStore } from 'modules/account-system/stores/AccountSystemStore';
import { CitiesStore } from 'modules/cities/stores/CitiesStore';
import { ClientGroupsStore } from 'modules/client-groups/stores/ClientGroupsStore';
import { ClustersStore } from 'modules/clusters/stores/ClustersStore';
import { CoreStore } from 'modules/core/stores/CoreStore';
import { CountriesStore } from 'modules/countries/stores/CountriesStore';
import { CourierDirectoryStore } from 'modules/couriers/stores/CourierDirectoryStore';
import { CouriersStore } from 'modules/couriers/stores/CouriersStore';
import { CoverageStore } from 'modules/coverages/stores/CoverageStore';
import { CurrencyStore } from 'modules/currency/stores/CurrencyStore';
import { DeliveryMethodsStore } from 'modules/delivery-methods/stores/DeliveryMethodsStore';
import { LinesStore } from 'modules/lines/stores/LinesStore';
import { LocationsStore } from 'modules/locations/stores/LocationsStore';
import { OrdersStore } from 'modules/orders/stores/OrdersStore';
import { PaymentMethodStore } from 'modules/payment-method/stores/PaymentMethodStore';
import { CourierCollectorMonitorStore } from 'modules/qa-monitor-courier-collector/stores/CourierCollectorMonitorStore';
import { CourierCouncilMonitorStore } from 'modules/qa-monitor-courier-council/stores/CourierCouncilMonitorStore';
import { FastmineMonitorStore } from 'modules/qa-monitor-fastmine/stores/FastmineStore';
import { OrderCollectorMonitorStore } from 'modules/qa-monitor-order-collector/stores/OrderCollectorMonitorStore';
import { RatesStore } from 'modules/rates/stores/RatesStore';
import { ReasonsDelayStore } from 'modules/reasonsDelay/stores/ReasonsDelayStore';
import { RouteGeneratorStore } from 'modules/routeGenerator/stores/RouteGeneratorStore';
import { RoutesStore } from 'modules/routes/stores/RoutesStore';
import { SchedulesStore } from 'modules/schedules/stores/SchedulesStore';
import { ServiceZonesStore } from 'modules/service-zones/stores/ServiceZonesStore';
import { ShopsStore } from 'modules/shops/stores/ShopsStore';
import { SupportStore } from 'modules/support/stores/SupportStore';
import { SupportFunctionalStore } from 'modules/supportFunctional/stores/SupportFunctionalStore';
import { TasksStore } from 'modules/tasks/stores/TasksStore';
import { TransportStore } from 'modules/transports/stores/TransportStore';
import { UserStore } from 'modules/user/stores/UserStore';
import { WarehousesStore } from 'modules/warehouses/stores/WarehousesStore';

export const routerStore = new RouterStore();
const courierDirectoryStore = new CourierDirectoryStore();
export const coreStore = new CoreStore();
const couriersStore = new CouriersStore();
const coverageStore = new CoverageStore();
const schedulesStore = new SchedulesStore();
const userStore = new UserStore();
const transportStore = new TransportStore();
const locationsStore = new LocationsStore();
const warehousesStore = new WarehousesStore();
const serviceZonesStore = new ServiceZonesStore();
const clientGroupsStore = new ClientGroupsStore();
const deliveryMethodsStore = new DeliveryMethodsStore();
const ratesStore = new RatesStore();
const ordersStore = new OrdersStore();
const shopsStore = new ShopsStore();
const supportStore = new SupportStore();
const tasksStore = new TasksStore();
const routesStore = new RoutesStore();
const routeGeneratorStore = new RouteGeneratorStore();
const currencyStore = new CurrencyStore();
const accountSystemStore = new AccountSystemStore();
const supportFunctionalStore = new SupportFunctionalStore();
const fastmineMonitorStore = new FastmineMonitorStore();
const countriesStore = new CountriesStore();
const citiesStore = new CitiesStore();
const clustersStore = new ClustersStore();
const orderCollectorMonitorStore = new OrderCollectorMonitorStore();
const courierCollectorMonitorStore = new CourierCollectorMonitorStore();
const courierCouncilMonitorStore = new CourierCouncilMonitorStore();
const reasonsDelayStore = new ReasonsDelayStore();
const paymentMethodStore = new PaymentMethodStore();
const linesStore = new LinesStore();

const store = {
  router: routerStore,
  core: coreStore,
  courierDirectory: courierDirectoryStore,
  couriers: couriersStore,
  coverage: coverageStore,
  schedules: schedulesStore,
  user: userStore,
  transport: transportStore,
  locations: locationsStore,
  warehouses: warehousesStore,
  serviceZones: serviceZonesStore,
  clientGroups: clientGroupsStore,
  deliveryMethods: deliveryMethodsStore,
  rates: ratesStore,
  orders: ordersStore,
  shops: shopsStore,
  support: supportStore,
  tasks: tasksStore,
  routes: routesStore,
  routeGenerator: routeGeneratorStore,
  currency: currencyStore,
  accountSystem: accountSystemStore,
  supportFunctional: supportFunctionalStore,
  fastmineMonitor: fastmineMonitorStore,
  countries: countriesStore,
  cities: citiesStore,
  clusters: clustersStore,
  orderCollectorMonitor: orderCollectorMonitorStore,
  courierCollectorMonitor: courierCollectorMonitorStore,
  courierCouncilMonitor: courierCouncilMonitorStore,
  reasonsDelay: reasonsDelayStore,
  paymentMethod: paymentMethodStore,
  lines: linesStore,
};

const StoreContext = createContext(store);

export const useStore = () => {
  return useContext<typeof store>(StoreContext);
};
