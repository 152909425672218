import './style.less';

import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import InfoSection from 'modules/common/components/InfoBlock/InfoSection';
import OrderTaskList from 'modules/orders/components/OrderTaskList';
import { IOrder } from 'modules/orders/models/types';
import { useStore } from 'services/store';

import NewTask from './NewTask';
import OrderAddress from './OrderAddress';
import OrderClient from './OrderClient';
import OrderComment from './OrderComment';
import OrderDeliveryMethod from './OrderDeliveryMethod';
import OrderDeliveryTime from './OrderDeliveryTime';
import OrderDetails from './OrderDetails';
import OrderParams from './OrderParams';
import OrderPayment from './OrderPayment';
import OrderProductView from './OrderProductView';
import OrderStatus from './OrderStatus';
import OrderTaskListHeader from './OrderTaskListHeader';
import OrderType from './OrderType';

interface IProps {
  order: IOrder;
  openMarkerEdit?: (target: string, isRepoint?: boolean) => void;
  isMarkerEdit?: boolean;
  orderUpdate: () => Promise<void>;
  isSupport?: boolean;
}

const OrderInfo = ({ order, openMarkerEdit, orderUpdate, isMarkerEdit, isSupport }: IProps) => {
  const { core } = useStore();

  const [isNewTaskModalOpened, updateNewTaskModalOpened] = useState(false);
  const openNewTaskModal = (): void => updateNewTaskModalOpened(true);
  const closeNewTaskModal = (): void => updateNewTaskModalOpened(false);

  const isPermittedToAddTask = core.permissions['tasks.create'];
  const isCanBeAddedNewTask = isPermittedToAddTask && order?.config?.extra_task;

  return (
    <>
      <InfoSection className="order-info">
        <OrderStatus order={order} />
        <OrderDeliveryTime order={order} />
        <OrderDeliveryMethod order={order} />
        <OrderClient order={order} />
        <OrderAddress order={order} isMarkerEdit={isMarkerEdit} openMarkerEdit={openMarkerEdit} />
        <OrderType order={order} />
        <OrderParams order={order} />
        <OrderProductView order={order} />
        <OrderComment order={order} />
        <OrderPayment order={order} />
      </InfoSection>

      <InfoSection header={<OrderTaskListHeader order={order} />} className="order__task-list">
        <OrderTaskList
          order={order}
          orderUpdate={orderUpdate}
          openNewTaskModal={openNewTaskModal}
          isCanBeAddedNewTask={isCanBeAddedNewTask}
          isSupport={isSupport}
        />
      </InfoSection>

      <OrderDetails order={order} />

      {isCanBeAddedNewTask ? (
        <NewTask
          order={order}
          onCreate={orderUpdate}
          isFormOpened={isNewTaskModalOpened}
          closeForm={closeNewTaskModal}
        />
      ) : null}
    </>
  );
};

export default observer(OrderInfo);
