import './style.less';

import React, { ReactNode } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Form, Input, Popconfirm, Table, TableColumnsType } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { observer } from 'mobx-react-lite';

import { getAbbreviatedNameCurrency } from 'helpers/currency';
import { timeFormat } from 'helpers/string';
import { TimePicker } from 'modules/common/containers/TimePicker';
import { translate } from 'modules/localization';
import getColumnChangeLimitsProps from 'modules/schedules/components/TimeslotsList/getColumnChangeLimitsProps';
import { getFieldName, minuteStep } from 'modules/schedules/helpers';
import { ITimeslotUI, IWeekday } from 'modules/schedules/models/uitypes';
import { useStore } from 'services/store';

interface IProps {
  weekday?: IWeekday;
  form: FormInstance;
}

const TimeslotsList = ({ weekday }: IProps) => {
  const { schedules, locations, core, currency } = useStore();

  const { scheduleActionTimeslotEdit, scheduleActionTimeslotDelete } = schedules;
  const isView = !core.permissions['tariffs.update'];

  const renderTime =
    (name) =>
    (_, row: ITimeslotUI): ReactNode => {
      const { id } = row;
      const fieldName = getFieldName(weekday.id, id, name);
      const propList = {
        clearText: null,
        format: timeFormat.simple,
        hideDisabledOptions: true,
        minuteStep,
        placeholder: translate('enterTime'),
        tabIndex: 0,
        onChange: (e): void => scheduleActionTimeslotEdit(e, name, id, weekday.id),
        disabled: isView,
        value: row[name],
        id: fieldName,
        allowClear: false,
        showNow: false,
      };

      return (
        <Form.Item
          className="timeslot__field"
          validateStatus={row[`error_${name}`] ? 'error' : null}
          help={row[`error_${name}`] ? row[`error_${name}`] : null}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*// @ts-ignore*/}
          <TimePicker {...propList} />
        </Form.Item>
      );
    };

  const renderLimit = (_, row: ITimeslotUI): ReactNode => {
    const { id } = row;
    const fieldName = getFieldName(weekday.id, id, 'limit');

    return (
      <Form.Item
        className="timeslot__field"
        validateStatus={row.error_limit ? 'error' : null}
        help={row.error_limit ? row.error_limit : null}
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              return value && value >= 0
                ? Promise.resolve()
                : Promise.reject(translate('orderLimitValidator'));
            },
          },
        ]}
      >
        <Input
          onChange={(e): void =>
            scheduleActionTimeslotEdit(e.target.value, 'limit', id, weekday.id)
          }
          placeholder={translate('enterOrderLimit')}
          id={fieldName}
          disabled={isView}
          value={row['limit']}
          type="number"
          min={0}
        />
      </Form.Item>
    );
  };

  const renderPrice = (_, row: ITimeslotUI): ReactNode => {
    const { id } = row;
    const fieldName = getFieldName(weekday.id, id, 'price');

    return (
      <Form.Item
        className="timeslot__field"
        validateStatus={row.error_price ? 'error' : null}
        help={row.error_price ? row.error_price : null}
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              return value && value >= 0
                ? Promise.resolve()
                : Promise.reject(translate('priceValidator'));
            },
          },
        ]}
      >
        <Input
          onChange={(e): void =>
            scheduleActionTimeslotEdit(e.target.value, 'price', id, weekday.id)
          }
          placeholder={translate('enterPrice')}
          addonAfter={getAbbreviatedNameCurrency(locations.currency, currency.currencyList).symbol}
          disabled={isView}
          id={fieldName}
          value={row['price']}
          type="number"
          min={0}
        />
      </Form.Item>
    );
  };

  const handleDelete =
    (weekId, intervalId) =>
    (e): void => {
      if (e) {
        e.preventDefault();
      }

      scheduleActionTimeslotDelete(weekId, intervalId);
    };

  const renderColumnAction = (intervalId: string): ReactNode =>
    isView ? null : (
      <div className="timeclot-actions">
        <Popconfirm
          cancelText={translate('cancel')}
          key="delete"
          onConfirm={handleDelete(weekday.id, intervalId)}
          title={translate('timeSlotDeleteConfirm')}
          placement="rightTop"
        >
          <a className="timeslot-delete" href="#" tabIndex={-1}>
            <CloseOutlined className="timeslot-delete-icon" />
          </a>
        </Popconfirm>
      </div>
    );

  const setLimits = (limitsValue: number, confirm: () => void) => {
    weekday.timeslots.map((timeslot) => {
      scheduleActionTimeslotEdit(limitsValue, 'limit', timeslot.id, weekday.id);
    });
    confirm();
  };

  const getColumnList = (): TableColumnsType<ITimeslotUI> => [
    {
      dataIndex: 'start',
      key: 'start',
      render: renderTime('start'),
      title: translate('from'),
    },
    {
      dataIndex: 'end',
      key: 'end',
      render: renderTime('end'),
      title: translate('to'),
    },
    {
      dataIndex: 'limit',
      key: 'limit',
      render: renderLimit,
      title: translate('orderLimit'),
      ...getColumnChangeLimitsProps(setLimits),
    },
    {
      dataIndex: 'price',
      key: 'price',
      render: renderPrice,
      title: translate('price'),
    },
    {
      dataIndex: 'id',
      key: 'id',
      render: renderColumnAction,
      title: '',
    },
  ];

  const dataSource: ITimeslotUI[] = weekday.timeslots
    .map((item) => ({
      ...item,
    }))
    .sort((a, b) => (a.start ? (a.start < b.start ? -1 : 1) : 1));

  return (
    <div className="timeslots-list">
      <Table<ITimeslotUI>
        bordered={false}
        columns={getColumnList()}
        dataSource={dataSource}
        locale={{ emptyText: '' }}
        pagination={false}
        rowKey={'id'}
      />
    </div>
  );
};

export default observer(TimeslotsList);
