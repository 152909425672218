import './style.less';

import React, { useState } from 'react';

import { Button, Collapse, CollapseProps } from 'antd';
import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import { CustomIcon } from 'modules/common/containers/CustomIcon';
import { translate } from 'modules/localization';
import RouteInfo from 'modules/routes/components/RouteInfo';
import RouteChangeStatus from 'modules/routes/components/RoutesViewPage/RouteChangeStatus';
import RouteStatus from 'modules/routes/components/RoutesViewPage/RouteStatus';
import { IRoute } from 'modules/routes/models/types';
import { useStore } from 'services/store';

interface IProps {
  route: IRoute;
}

const RouteResult = ({ route: initialRouteData }: IProps) => {
  const { routes } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const [route, setRoute] = useState<IRoute>(initialRouteData);
  const routeUpdate = (): Promise<void> =>
    routes.apiStore.getRoute(initialRouteData.guid).then(setRoute);
  const routeResend = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await routes.apiStore.resendRoute(route.guid);
      await routeUpdate();
    } finally {
      setIsLoading(false);
    }
  };

  const routeItem: CollapseProps['items'] = [
    {
      key: route.guid,
      label: (
        <div className="route-result__title-wrap">
          <Title
            size={Title.SIZE.H3}
            weight={Title.WEIGHT.SEMIBOLD}
            className="route-result__title"
          >
            {translate('route')} #{route.number}
            <CustomIcon className="route__title-icon" type="icon-down" />
          </Title>
          <RouteStatus route={route} />
        </div>
      ),
      children: (
        <div className="route-result__main">
          <div className="route-result__info">
            <RouteChangeStatus route={route} routeUpdate={routeUpdate} />
            <RouteInfo route={route} routeUpdate={routeUpdate} />
          </div>
          <div className="route-result__resend">
            <Button type="primary" onClick={routeResend} disabled={isLoading}>
              {translate('routeResend')}
            </Button>
          </div>
        </div>
      ),
      className: 'route-result__body',
    },
  ];

  return (
    <Collapse
      bordered={false}
      expandIcon={() => null}
      className="route-result__route-head"
      items={routeItem}
    />
  );
};

export default observer(RouteResult);
