import './style.less';

import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, TableColumnType } from 'antd';

import { translate } from 'modules/localization';
import EditIcon from 'modules/schedules/components/TimeslotsList/EditIcon';
import { ITimeslotUI } from 'modules/schedules/models/uitypes';

const getColumnChangeLimitsProps = (
  setLimits: (limitsValue: number, confirm: () => void) => void,
): TableColumnType<ITimeslotUI> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, close, confirm }) => (
    <div className="timeslot__dropdown-limits" onKeyDown={(e) => e.stopPropagation()}>
      <div className="timeslot__dropdown-limits-header">
        <div style={{ width: '140px' }}>{translate('specifyWhatLimitYouWantToSet')}</div>
        <Button
          onClick={close}
          icon={<CloseOutlined />}
          className="timeslot__close-confirm-button"
        />
      </div>
      <Input
        type="number"
        placeholder={translate('enterOrderLimit')}
        value={selectedKeys[0]}
        min={0}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => setLimits(selectedKeys[0] as number, confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => setLimits(selectedKeys[0] as number, confirm)}
        size="small"
        style={{ width: '100%', height: '30px' }}
      >
        {translate('applyToAll')}
      </Button>
    </div>
  ),
  filterIcon: () => <EditIcon />,
  className: 'timeslot__dropdown-limits-wrapper',
});

export default getColumnChangeLimitsProps;
