import './style.less';

import React, { ReactNode, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { observer } from 'mobx-react-lite';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';

import { LangControlMobile } from 'modules/common/components/LangControl';
import { CustomIcon } from 'modules/common/containers/CustomIcon';
import { SECTION_DATA } from 'modules/common/containers/RouterSection/constants';
import { isPermitted } from 'modules/core/helpers/permissions';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  children: ReactNode;
}

export const PageTitle = ({ children }: IProps) => {
  const target = document.getElementById('pagetitle');
  return target ? ReactDOM.createPortal(children, target) : null;
};

const HeaderMobile = () => {
  const { core } = useStore();
  const [visible, setVisible] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();
    core.logout();
  };

  const checkAccess = (permissions?: string[] | string): boolean => {
    return isPermitted(core.permissions, permissions);
  };

  return (
    <>
      <div className="mobile-header">
        <div className="mobile-header-title">
          <NavLink className="header__logo" to="/" exact>
            <CustomIcon className="header__logo-icon" type="md-logo" />
          </NavLink>

          <div className="pagetitle" id="pagetitle" />
        </div>

        <Button
          className="header-menu-button"
          type="text"
          icon={<MenuOutlined />}
          onClick={() => setVisible(true)}
        />
      </div>
      <Drawer
        className="mobile-menu"
        title={translate('menu')}
        placement="right"
        onClose={() => setVisible(false)}
        open={visible}
      >
        <div className="menu">
          {checkAccess(SECTION_DATA.arm2.access) && (
            <div className="menu__item" onClick={() => setVisible(false)}>
              <NavLink to="/arm2" className="menu__item-title" title={translate('planning')}>
                <CustomIcon className="menu__item-icon" type="arm" />
                <span className="menu__item-text">{translate('planning')}</span>
              </NavLink>
            </div>
          )}
          {checkAccess(SECTION_DATA.geo.access) && (
            <div className="menu__item" onClick={() => setVisible(false)}>
              <NavLink
                to="/geo/locations"
                className="menu__item-title"
                title={translate('tariffing')}
              >
                <CustomIcon className="menu__item-icon" type="geo" />
                <span className="menu__item-text">{translate('tariffing')}</span>
              </NavLink>
            </div>
          )}
          {checkAccess(SECTION_DATA.directories.access) && (
            <div className="menu__item" onClick={() => setVisible(false)}>
              <NavLink
                to="/directories"
                className="menu__item-title"
                title={translate('directory')}
              >
                <CustomIcon className="menu__item-icon" type="dir" />
                <span className="menu__item-text">{translate('directory')}</span>
              </NavLink>
            </div>
          )}

          {checkAccess(SECTION_DATA.support.access) && (
            <div className="menu__item" onClick={() => setVisible(false)}>
              <NavLink to="/support" className="menu__item-title" title={translate('support')}>
                <CustomIcon className="menu__item-icon" type="support" />
                <span className="menu__item-text">{translate('support')}</span>
              </NavLink>
            </div>
          )}
        </div>

        <LangControlMobile />

        {core.accountData && (
          <div className="menu__item header__item--logout">
            <a
              href="#"
              onClick={handleLogout}
              className="menu__item-title"
              title={`${core.accountData.name} ${core.accountData.surname}`}
            >
              <CustomIcon className="menu__item-icon" type="logout" />
              <span className="menu__item-text">{translate('logout')}</span>
            </a>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default observer(HeaderMobile);
