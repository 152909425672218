import './style.less';

import React, { useEffect, useMemo } from 'react';

import { Button, Input } from 'antd';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const SupportSearch = () => {
  const { support } = useStore();

  const sendRequest = (query) => {
    if (query && query.trim() && query.length > 2) {
      support.search();
    }
  };

  const search = useMemo(() => debounce(sendRequest, 300), []);

  useEffect(() => {
    return () => {
      search.cancel();
    };
  }, [search]);

  const handleChange = (e) => {
    support.searchSet(e.target.value);
  };

  const { Search } = Input;

  return (
    <Search
      value={support.query}
      className="support-search"
      placeholder={translate('supportSearchPlaceholder')}
      allowClear
      enterButton={
        <Button
          type="primary"
          disabled={support.query && support.query.length < 3}
          loading={support.isLoading}
        >
          {translate('supportSearch')}
        </Button>
      }
      onChange={handleChange}
      onSearch={search}
      loading={support.isLoading}
    />
  );
};

export default observer(SupportSearch);
