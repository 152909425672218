import React from 'react';

import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';

interface IProps {
  order: IOrder;
}

const OrderTaskListHeader = ({ order }: IProps) => {
  const tasksLength = (order.tasks && order.tasks.length) || 0;
  return (
    <div className="new-task-header">
      <div>
        {tasksLength > 0
          ? `${tasksLength} ${translate('task', { count: tasksLength })} ${translate('byOrder')}`
          : translate('noTasks')}
      </div>
    </div>
  );
};

export default OrderTaskListHeader;
