import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import icon from 'img/404.svg?react';
import Layout from 'modules/common/containers/Layout';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import SupportResults from '../SupportResults';
import SupportSearch from '../SupportSearch';

const Support = () => {
  const { support } = useStore();
  return (
    <Layout>
      {support.mode === 'result' ? (
        <SupportResults />
      ) : (
        <div className="support">
          <div className="support__wrapper">
            <div className="support__content">
              <div className="support__title">{translate('supportTitle')}</div>
              <img alt="" className="support__img" src={`${icon}`} />
              <div className="support__text">{translate('supportEnter')}</div>
              <SupportSearch />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default observer(Support);
