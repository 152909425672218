import React from 'react';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';

interface IProps {
  order: IOrder;
}

const OrderParams = ({ order }: IProps) => {
  return (
    <InfoFact header={translate('orderParameters')}>
      {order?.sizes?.weight && order?.sizes?.volume ? `${translate('weight')}:\u00A0` : null}
      {order?.sizes?.weight
        ? `${+order.sizes.weight.toFixed(2)} ${translate('weightUnit')}`
        : `0 ${translate('weightUnit')}`}
      ,{' '}
      {order?.sizes?.volume ? (
        <>
          {+order.sizes.volume.toFixed(3)} {translate('lengthUnit')}
          <sup>3</sup>
        </>
      ) : (
        <>
          0 {translate('lengthUnit')}
          <sup>3</sup>
        </>
      )}
      <div className="order__metrics">
        {order?.sizes?.length ? (
          <span className="order__metric">
            {translate('lengthTemplate', { content: +order.sizes.length.toFixed(2) })}
          </span>
        ) : null}
        {order?.sizes?.width ? (
          <span className="order__metric">
            {translate('widthTemplate', { content: +order.sizes.width.toFixed(2) })}
          </span>
        ) : null}
        {order?.sizes?.height ? (
          <span className="order__metric">
            {translate('heightTemplate', { content: +order.sizes.height.toFixed(2) })}
          </span>
        ) : null}
      </div>
      {Number.isInteger(order?.meta?.total_box) &&
        `${order?.meta?.total_box}\u00A0${translate('bag', {
          count: order?.meta?.total_box,
        })}`}
      {Number.isInteger(order?.meta?.order_number) && `, №\u00A0${order?.meta?.order_number}`}
    </InfoFact>
  );
};

export default OrderParams;
