import React from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  order: IOrder;
}

const OrderDeliveryTime = ({ order }: IProps) => {
  return (
    <InfoFact header={translate('orderDeliveryDateTime')}>
      {dayjs(order.delivery_date).format('DD.MM.YY')} / {order.time_slot_start} –{' '}
      {order.time_slot_end}
    </InfoFact>
  );
};

export default OrderDeliveryTime;
