import { makeAutoObservable } from 'mobx';

import { RestException } from 'api/RestException';
import OrdersApi from 'modules/orders/api/OrdersApi';
import { IOrder } from 'modules/orders/models/types';
import RoutesApi from 'modules/routes/api/RoutesApi';
import { IRoutes } from 'modules/routes/models/types';
export class SupportStore {
  mode: 'result' | 'search' = 'search';
  query: string = null;
  activeRequestOrderList: AbortController | null = null;
  activeRequestRoutesList: AbortController | null = null;
  orderList: IOrder[] = [];
  routesList: IRoutes[] = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  searchSet = (query: string): void => {
    this.query = query;
  };

  private createAbortController = (controller: AbortController | null): AbortController => {
    if (controller) {
      controller.abort();
    }
    return new AbortController();
  };

  searchOrders = async (search: string): Promise<IOrder[]> => {
    try {
      this.activeRequestOrderList = this.createAbortController(this.activeRequestOrderList);

      const request = this.activeRequestOrderList;

      const { data: res } = await OrdersApi.getOrdersList(
        { current: 1, pageSize: 100, search },
        request.signal,
      );
      this.orderList = res.data;
      return res.data;
    } catch (e) {
      throw new RestException(e);
    } finally {
      if (this.activeRequestOrderList && !this.activeRequestOrderList.signal.aborted) {
        this.activeRequestOrderList = null;
      }
    }
  };

  searchRoutes = async (search: string): Promise<IRoutes[]> => {
    try {
      this.activeRequestRoutesList = this.createAbortController(this.activeRequestRoutesList);
      const request = this.activeRequestRoutesList;

      const { data: res } = await RoutesApi.getRoutesList(
        { current: 1, pageSize: 100, search },
        request.signal,
      );
      this.routesList = res.data;
      return res.data;
    } catch (e) {
      if (e?.status !== 400) {
        throw new RestException(e);
      }
    } finally {
      if (this.activeRequestRoutesList && !this.activeRequestRoutesList.signal.aborted) {
        this.activeRequestRoutesList = null;
      }
    }
  };

  search = async (): Promise<void> => {
    try {
      this.setLoading(true);
      this.routesList = [];
      this.orderList = [];
      const query = this.query[0] === '#' ? this.query.slice(1) : this.query;
      const promises: Promise<IOrder[] | IRoutes[]>[] = [this.searchOrders(query)];
      if (!isNaN(Number(query))) {
        promises.push(this.searchRoutes(query));
      }
      await Promise.all(promises);
      this.mode = 'result';
    } catch (e) {
      throw new RestException(e);
    } finally {
      this.setLoading(false);
    }
  };

  setLoading = (value: boolean): void => {
    this.isLoading = value;
  };
}
