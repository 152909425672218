import React, { useState } from 'react';

import { FormInstance } from 'antd/lib/form/hooks/useForm';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';

import { getNextHourDate } from 'helpers/date';
import { getFieldDataList } from 'helpers/form';
import OrderNewTaskForm, { INewTaskForm } from 'modules/orders/components/OrderNewTaskForm';
import { prepareData } from 'modules/orders/components/OrderNewTaskForm/helpers';
import { IOrder } from 'modules/orders/models/types';
import { ITaskForm } from 'modules/tasks/models/types';
import { useStore } from 'services/store';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  order: IOrder;
  isFormOpened: boolean;
  closeForm: () => void;
  onCreate: () => Promise<void>;
}

const OrderNewTask = ({ order, onCreate, isFormOpened, closeForm }: IProps) => {
  const [isLoadingTaskSubmit, setIsLoadingTaskSubmit] = useState(false);
  const { orders } = useStore();

  const createTask = (taskForm: ITaskForm): Promise<void> =>
    orders.apiStore.createTask(order.guid, taskForm).then(onCreate);

  const handleError = (values, form: FormInstance) => (response) => {
    const fieldDataList = getFieldDataList(response, values);
    form.setFields(fieldDataList);
  };

  const handleNewTaskSubmit = (values: INewTaskForm, form: FormInstance) => {
    setIsLoadingTaskSubmit(true);
    const preparedData = prepareData(values);
    createTask(preparedData)
      .then(() => {
        form.resetFields();
        closeForm();
        setIsLoadingTaskSubmit(false);
      })
      .catch(handleError(values, form))
      .then(() => setIsLoadingTaskSubmit(false));
  };

  const handleNewTaskCancel = (form: FormInstance): void => {
    form.resetFields();
    closeForm();
  };

  const newTaskInitialValues =
    order.delivery_method_guid === '529986cb-c469-4c12-adec-98428bf04b57' ||
    order.delivery_method_guid === 'e6aee8a0-aaa2-4eaf-ab89-c592aad9848b'
      ? {
          task_destination: 'recipient',
          delivery_date: dayjs(),
          time_slot_start: getNextHourDate(dayjs()),
          time_slot_end: getNextHourDate(dayjs(), 2),
        }
      : undefined;

  return (
    <OrderNewTaskForm
      isModalOpened={isFormOpened}
      onCancel={handleNewTaskCancel}
      onSubmit={handleNewTaskSubmit}
      order={order}
      loading={isLoadingTaskSubmit}
      initialValues={newTaskInitialValues}
    />
  );
};

export default observer(OrderNewTask);
