import React from 'react';

import { observer } from 'mobx-react-lite';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import Status from 'modules/common/components/Status';
import { translate } from 'modules/localization';
import { IOrder, IOrderStatus } from 'modules/orders/models/types';
import { useStore } from 'services/store';

interface IProps {
  order: IOrder;
}

const OrderStatus = ({ order }: IProps) => {
  const { orders } = useStore();

  const status: IOrderStatus =
    orders.orderStatuses && orders.orderStatuses.find((s) => s.value === order.status);

  return (
    <InfoFact header={translate('orderStatus')}>
      <Status status={status} />
    </InfoFact>
  );
};

export default observer(OrderStatus);
