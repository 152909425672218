import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import InfoSection from 'modules/common/components/InfoBlock/InfoSection';
import { translate } from 'modules/localization';
import { IOrder } from 'modules/orders/models/types';
import { IWarehouses } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  order: IOrder;
}

const OrderDetails = ({ order }: IProps) => {
  const { clientGroups, shops, warehouses } = useStore();

  const [warehouse, setWarehouse] = useState<IWarehouses>(null);

  useEffect(() => {
    order.warehouse_guid && warehouses.get(order.warehouse_guid).then((item) => setWarehouse(item));
  }, [order.warehouse_guid]);

  const shop = shops.listAll.find((s) => s.guid === order.shop_guid);
  const clientGroup = clientGroups.listAll.find((s) => s.guid === order.client_group_guid);

  return (
    <InfoSection header={translate('orderDetails')} className="order-info">
      <InfoFact header={translate('shop')}>{shop ? shop.short_name : null}</InfoFact>
      <InfoFact header={translate('warehouse')}>{warehouse ? warehouse.title : null}</InfoFact>
      <InfoFact header={translate('clientGroup')}>{clientGroup ? clientGroup.name : null}</InfoFact>
      <InfoFact header={translate('createdDateTime')}>
        {dayjs(order.created_at).format('DD.MM.YY')} /{' '}
        {warehouse && warehouse.timezone
          ? dayjs.tz(order.created_at, warehouse.timezone).format('HH:mm')
          : null}
      </InfoFact>
    </InfoSection>
  );
};

export default observer(OrderDetails);
