import React from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import {
  getCustomerAddressProp,
  getCustomerPointProp,
  getWarehouseAddressProp,
  getWarehousePointProp,
} from 'modules/orders/helpers/propHelper';
import { IOrder } from 'modules/orders/models/types';
import { useStore } from 'services/store';

interface IProps {
  order: IOrder;
  openMarkerEdit?: (target: string, isRepoint?: boolean) => void;
  isMarkerEdit?: boolean;
}

const OrderAddress = ({ order, isMarkerEdit, openMarkerEdit }: IProps) => {
  const { core } = useStore();

  const getAddressFactTitle = () => {
    return (
      <>
        {translate('addressTitle')}{' '}
        {(!order.sender_point || !order.point) && (
          <WarningOutlined className="order__address-warning" />
        )}
      </>
    );
  };

  const isNeedToSetCustomerCoords =
    order.status === 'no_coordinates' && !order[getCustomerPointProp(order)];
  const isCustomerCoordsCanBeRepoint =
    order.status === 'awaiting_completion' ||
    order.status === 'canceled' ||
    order.status === 'completed';
  const isNeedToSetWarehouseCoords = !order[getWarehousePointProp(order)];

  const Intercom = () => {
    let intercomContent = '-';
    if (order?.meta?.call_on_arrival) {
      intercomContent = translate('intercomNotWorking');
    }
    if (!order?.meta?.call_on_arrival && order?.meta?.intercom) {
      intercomContent = `${order.meta.intercom}`;
    }
    return (
      <div className="order-info__intercom">
        {translate('intercom')}: {intercomContent}
      </div>
    );
  };

  const Barrier = () => {
    let barrierContent = translate('barrierNotExist');
    if (order?.meta?.barrier === 'true') {
      barrierContent = translate('barrierExist');
    }
    if (order?.meta?.barrier === 'false') {
      barrierContent = translate('barrierNotExist');
    }
    if (order?.meta?.barrier === 'call') {
      barrierContent = translate('barrierCall');
    }
    return (
      <div className="order-info__barrier">
        {translate('barrier')}: {barrierContent}
      </div>
    );
  };

  return (
    <InfoFact className="order-info__address" header={getAddressFactTitle()}>
      <div className="order__infofact-item">
        <p className="order__infofact-title">{translate('warehouse')}</p>
        <p>{order[getWarehouseAddressProp(order)]}</p>
        {isNeedToSetWarehouseCoords &&
          (openMarkerEdit ? (
            <Link
              className={`order-coords__edit ${isMarkerEdit && 'order-coords__edit_disabled'}`}
              to="#"
              onClick={() =>
                openMarkerEdit &&
                openMarkerEdit(order.type === 'customer_to_warehouse' ? 'recipient' : 'sender')
              }
            >
              {translate('geocode')}
            </Link>
          ) : (
            <Link to={`/arm2/orders/${order.guid}`} target="_blank">
              {translate('geocode')}
            </Link>
          ))}
      </div>
      <div className="order__infofact-item">
        <p className="order__infofact-title">{translate('client')}</p>
        <p>{order[getCustomerAddressProp(order)]}</p>
        {isNeedToSetCustomerCoords &&
          (openMarkerEdit ? (
            <Link
              className={`order-coords__edit ${isMarkerEdit && 'order-coords__edit_disabled'}`}
              to="#"
              onClick={() =>
                openMarkerEdit &&
                openMarkerEdit(order.type === 'customer_to_warehouse' ? 'sender' : 'recipient')
              }
            >
              {translate('geocode')}
            </Link>
          ) : (
            <Link to={`/arm2/orders/${order.guid}`} target="_blank">
              {translate('geocode')}
            </Link>
          ))}

        <Intercom />
        <Barrier />

        {core.permissions['orders.update'] &&
          isCustomerCoordsCanBeRepoint &&
          (openMarkerEdit ? (
            <Button
              type="link"
              className={`order-coords__edit ${isMarkerEdit && 'order-coords__edit_disabled'}`}
              onClick={() =>
                openMarkerEdit &&
                openMarkerEdit(
                  order.type === 'customer_to_warehouse' ? 'sender' : 'recipient',
                  true,
                )
              }
            >
              {translate('geocode')}
            </Button>
          ) : (
            <Link to={`/arm2/orders/${order.guid}`} target="_blank">
              {translate('geocode')}
            </Link>
          ))}
      </div>
    </InfoFact>
  );
};

export default OrderAddress;
