import React from 'react';

import { observer } from 'mobx-react-lite';

import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import { translate } from 'modules/localization';
import { renderType } from 'modules/orders/components/OrdersList/helpers';
import { IOrder } from 'modules/orders/models/types';
import { useStore } from 'services/store';

interface IProps {
  order: IOrder;
}

const OrderType = ({ order }: IProps) => {
  const { orders } = useStore();

  return (
    <InfoFact header={translate('orderType')}>
      <p className="order__type">{renderType(order.type, orders.orderTypes)}</p>
    </InfoFact>
  );
};

export default observer(OrderType);
