import { DEFAULT_PAGINATION } from 'constants/index';
import { IRatesFilter } from 'modules/rates/models/types';

export const initialFilter: IRatesFilter = {
  ...DEFAULT_PAGINATION,
  locationGuid: null,
  serviceZoneGuidList: [],
  deliveryMethodGuidList: [],
  activeOnly: true,
};
