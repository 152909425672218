import Api from 'api/Api';

import {
  IRouteDeleteTaskFromRouteResponse,
  IRouteGetResponse,
  IRouteResendResponse,
  IRouteSetStatusResponse,
  IRoutesFilter,
  IRoutesListGetResponse,
  IRoutesStatusGetResponse,
} from '../models/types';

export default class RoutesApi {
  static getRoutesList(
    filter: IRoutesFilter,
    signal?: AbortSignal,
  ): Promise<IRoutesListGetResponse> {
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      status: filter.status,
      warehouse_guid_list: filter.warehouses,
      problem_tasks_exist: filter.isProblem,
      delivery_date: filter.deliveryDate,
      number: filter.search,
    };
    if (filter.order) {
      const sortQuery = `sort[${filter.columnKey}]`;
      query[sortQuery] = filter.order === 'ascend' ? 'asc' : 'desc';
    }
    return Api.get('/api/v1/routes', query, signal);
  }

  static getRoutesStatuses(): Promise<IRoutesStatusGetResponse> {
    return Api.get('/api/v1/dictionaries/route-statuses');
  }

  static getRoute(routeGuid: string, signal?: AbortSignal): Promise<IRouteGetResponse> {
    return Api.get(`/api/v1/routes/${routeGuid}`, {}, signal);
  }

  static setRouteStatus(routeGuid: string, status: string): Promise<IRouteSetStatusResponse> {
    return Api.put({ status }, `/api/v1/routes/${routeGuid}/status`);
  }

  static deleteTaskFromRoute(guid: string): Promise<IRouteDeleteTaskFromRouteResponse> {
    return Api.delete(`/api/v1/tasks/${guid}/route`);
  }

  static resendRoute(guid: string): Promise<IRouteResendResponse> {
    return Api.post({}, `/api/v1/support/send_to_account_system/route/${guid}`);
  }
}
