import './style.less';

import React, { useEffect } from 'react';

import { Button, Form, Input, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { getFieldDataList } from 'helpers/form';
import Title from 'modules/common/components/Title';
import Loader from 'modules/common/containers/Loader';
import { getFieldErrorList } from 'modules/directory/containers/Form/helpers';
import { translate } from 'modules/localization';
import Week from 'modules/schedules/components/Week';
import { fromFormDataToApi } from 'modules/schedules/helpers';
import { ISchedules } from 'modules/schedules/models/apitypes';
import { useStore } from 'services/store';

interface IParams {
  entityGuid: string;
}

interface IProps {
  actionName: string;
  onCancel?: () => void;
  onOk?: (guid: string) => void;
  scheduleGuid?: string;
  baseUrl?: string;
  rateName?: string;
}

const SchedulesForm = ({ baseUrl, scheduleGuid, actionName, onCancel, onOk, rateName }: IProps) => {
  const { schedules, router, core, currency } = useStore();

  const { entityGuid } = useParams<IParams>();
  const [form] = Form.useForm();

  const { resetFormData, loadingForm } = schedules;

  useEffect(() => {
    currency.getCurrency();
    if (scheduleGuid || entityGuid) {
      schedules.apiStore.getSchedule(scheduleGuid || entityGuid).then((item) => {
        form.setFieldsValue({
          name: item.name,
          average_speed: item.average_speed !== 0 ? item.average_speed : null,
        });
      });
    }
    if (actionName === 'create' && rateName) {
      form.setFieldsValue({
        name: rateName,
      });
    }
  }, []);

  const handleReset = (): void => {
    if (onCancel) {
      schedules.resetFormData();
      onCancel();
    } else {
      router.push({ pathname: baseUrl, search: router.location.search });
      resetFormData();
    }
  };

  const handleSubmit = (values): void => {
    const data: ISchedules = fromFormDataToApi(values, schedules.schedulesWeek);
    const request =
      actionName === 'create' || actionName === 'copy'
        ? schedules.apiStore.createSchedule(data)
        : schedules.apiStore.editSchedule(scheduleGuid || entityGuid, data);
    request
      .then((item) => {
        onOk(item.guid);
        onCancel();
      })
      .catch((e) => {
        schedules.setTimeslotsErrors(getFieldErrorList(e));
        form.setFields(getFieldDataList(e, values));
      });
  };

  const isView = !core.permissions['tariffs.update'];

  const isCreating = actionName === 'create' && !scheduleGuid && core.permissions['tariffs.create'];
  const isEditing = scheduleGuid && core.permissions['tariffs.update'];
  const isCopying = actionName === 'copy' && scheduleGuid && core.permissions['tariffs.create'];
  const isEditingOnCopying =
    actionName === 'copy' && scheduleGuid && core.permissions['tariffs.create'];

  return (
    <Form
      className="schedules__form"
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      autoComplete="off"
    >
      <div className="schedules__form-body">
        <Title
          size={Title.SIZE.H2}
          weight={Title.WEIGHT.SEMIBOLD}
          className="schedules__form-header"
        >
          {actionName === 'edit'
            ? core.permissions['tariffs.update']
              ? translate('scheduleEditTitle')
              : translate('scheduleViewTitle')
            : translate('scheduleCreateTitle')}
        </Title>
        <div className="schedules__form-fields">
          <Form.Item
            label={translate('title')}
            name="name"
            rules={[{ required: true, message: translate('requiredValidator') }]}
          >
            <Input placeholder={translate('enterTitle')} disabled={isView} />
          </Form.Item>
          <Form.Item label={translate('averageSpeed')} name="average_speed">
            <Input
              placeholder={translate('enterAverageSpeed')}
              type="number"
              min={0}
              addonAfter={translate('averageSpeedUnitKmPerHour')}
              disabled={isView}
            />
          </Form.Item>
        </div>

        <Week form={form} />
      </div>
      <Spin
        indicator={<Loader show />}
        spinning={loadingForm}
        wrapperClassName="schedules__form-footer"
      >
        <Button htmlType="button" key="cancel" onClick={handleReset}>
          {translate('cancel')}
        </Button>
        {(isEditing || isEditingOnCopying) && (
          <Button htmlType="submit" key="save" type="primary">
            {translate('save')}
          </Button>
        )}
        {(isCreating || isCopying) && (
          <Button htmlType="submit" key="save" type="primary">
            {translate('add')}
          </Button>
        )}
      </Spin>
    </Form>
  );
};

export default observer(SchedulesForm);
