import React from 'react';

const EditIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.7555 13.9557L6.74312 12.2673C6.80746 12.2402 6.86504 12.2012 6.91583 12.1521L13.6414 5.42554C14.1173 4.94966 14.1206 4.17404 13.6448 3.69817L12.3021 2.35522C11.8263 1.87934 11.0508 1.88273 10.575 2.3586L3.84934 9.0852C3.80024 9.13431 3.7613 9.19358 3.7342 9.25794L2.04433 13.2444C1.94951 13.468 2.01555 13.6932 2.15947 13.8389C2.3034 13.9845 2.5303 14.0506 2.7555 13.9557ZM11.4402 3.21721L12.7813 4.55847L11.7281 5.61182L10.387 4.27057L11.4402 3.21721ZM4.80434 9.85405L9.52514 5.13256L10.8662 6.47382L6.1454 11.1953L3.81887 12.1809L4.80434 9.85405Z"
    />
  </svg>
);

export default EditIcon;
